import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as Yup from 'yup';
import { AuctionStatus } from '../../../constants/AuctionStatus';
import CommonButton from '../CommonButton';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import { fetchOrganizationDetails } from '../../../settings/SettingsActions';
import FormCurrencyInputField from '../formFields/FormCurrencyInputField';
import { preparePriceFormat } from '../../helpers/preparePriceFormat';
import { prepareBidsData } from '../../helpers/prepareBidsData';
import { fetchAuctionBids, placeBid } from '../../../search/SearchActions';
import CommonErrorLabel from '../CommonErrorLabel';
import { isDateBefore } from '../../utils/date/Date.utils';
import { pencesToPounds } from '../../helpers/pencesToPounds';
import {
  AUCTION_BID_MAX_BIDS_LIMIT,
  AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE,
  AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE,
} from '../../../constants/AuctionBid';
import SearchConfirmBidModal from '../../../search/bidModals/SearchConfirmBidModal';
import { getTitle } from '../../helpers/vehicleHelper';
import BoxStatusVariants from '../../../constants/BoxStatusVariants';
import { AuctionStatusType } from '../../../constants/AuctionStatusType';
import { validateBidAmount } from '../../helpers/AuctionBidHelper';

const PlaceBidBox = ({
  item,
  refreshItem,
  bids,
  status,
  changeStatus,
  highestBidInPence,
  proxyBidInPence,
  shouldPriceBeGreen,
}) => {
  const bidFormRef = useRef(null);
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const [bidToSend, setBidToSend] = useState(0);
  const [validationRequiredBid, setValidationRequiredBid] = useState(null);
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const bidErrorMessage = useSelector(state => state.search.get('bidErrorMessage'));
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const [bidsNotifications, setBidsNotifications] = useState({});
  const bidsData = bids && bids.length > 0 ? bids : item?.bids;
  const [bidsLeft, setBidsLeft] = useState(AUCTION_BID_MAX_BIDS_LIMIT - bidsData.length);

  useEffect(() => {
    dispatch(fetchOrganizationDetails());
  }, [dispatch]);

  useEffect(() => {
    if (status) {
      changeStatus(false);
      refreshItem();
      setBidsNotifications({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const {
    bidMyOrganizationIsHighest,
    highestBidMyOrganization,
    bidsMyOrganization,
  } = prepareBidsData(bidsData, organizationId);

  const addNotification = (id, message, className) => {
    const notifications = cloneDeep(bidsNotifications);
    notifications[id] = {
      className,
      message,
    };
    setBidsNotifications(notifications);
  };

  const handleBid = async () => {
    await dispatch(
      placeBid(item?.id, bidToSend * 100, response => {
        const { success, auctionId, result } = response;
        setBidsLeft(result?.bidsLeft);
        if (!success) {
          addNotification(auctionId, result, AuctionStatus.PUBLISHED);
        } else {
          let message;
          let color;

          const bidAmount = `£${bidToSend.toLocaleString('en-GB')}`;

          if (result.isHighestBid) {
            if (result.proxyBidInPence) {
              message = `Your bid of ${result.proxyBidInPence} is the highest. Your maximum bid is ${bidAmount}`;
            } else {
              message = `Your bid of ${bidAmount} is the highest`;
            }
            color = AuctionStatus.PUBLISHED;
          } else {
            message = `Your bid of ${bidAmount} is not the highest - try again`;
            color = AuctionStatus.REJECTED;
          }
          addNotification(auctionId, message, color);
          dispatch(fetchAuctionBids(item?.id));
        }
      }),
    );
    setShowConfirm(false);
    if (bidsLeft !== 1) {
      bidFormRef.current.resetForm();
    }
    bidFormRef.current.setSubmitting(false);
  };

  const handleSubmit = async ({ bid }) => {
    setBidToSend(bid);
    setShowConfirm(true);
  };

  const renderBidMessage = () => {
    let message;
    let statusColor;
    if (bidMyOrganizationIsHighest) {
      if (item.proxyBidInPence) {
        message = `Your bid of ${preparePriceFormat(
          item.proxyBidInPence,
          true,
        )} is the highest. Your maximum bid is ${preparePriceFormat(
          highestBidMyOrganization?.amountInPence,
          true,
        )}`;
      } else {
        message = `Your bid of ${preparePriceFormat(
          highestBidMyOrganization?.amountInPence,
          true,
        )} is the highest`;
      }
      statusColor = BoxStatusVariants.GREEN;
    } else if (bidsMyOrganization?.length === AUCTION_BID_MAX_BIDS_LIMIT) {
      message = `Your bid of ${preparePriceFormat(
        highestBidMyOrganization?.amountInPence,
        true,
      )} was not the highest`;
      statusColor = BoxStatusVariants.SECONDARY;
    } else {
      message = `Your bid of ${preparePriceFormat(
        highestBidMyOrganization?.amountInPence,
        true,
      )} is not the highest - try again`;
      statusColor = BoxStatusVariants.RED;
    }
    return (
      <div className={`status-box p-1 text-sm text-uppercase text-center box-${statusColor}`}>
        {message}
      </div>
    );
  };
  const getInitBidValue = useCallback(() => {
    const isCurrentBidderWinning = item.bids.some(it => it.isHighest);
    if (proxyBidInPence && !isCurrentBidderWinning) {
      return proxyBidInPence + pencesToPounds(AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE);
    }
    if (highestBidInPence) {
      return highestBidInPence + pencesToPounds(AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE);
    }
    if (item?.minimumPriceInPence) {
      return pencesToPounds(item?.minimumPriceInPence);
    }
    return pencesToPounds(AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE);
  }, [item, proxyBidInPence, highestBidInPence]);

  const bidInitValue = getInitBidValue();

  const validationSchema = Yup.object().shape({
    bid: Yup.number()
      .required('Amount value is required')
      .integer('Bid must be an integer')
      .test(
        'required bid amount',
        `Your bid must be greater than or equal to ${preparePriceFormat(
          validationRequiredBid || 100,
        )}`,
        value =>
          validateBidAmount(value, item, highestBidMyOrganization, setValidationRequiredBid),
      ),
  });

  const getBidTitle = () => {
    let text = 'CURRENT BID';
    const priceGreenCondition =
      shouldPriceBeGreen !== null ? shouldPriceBeGreen : item?.shouldPriceBeGreen;

    if (
      item.type === AuctionStatusType.PHYSICAL ||
      item.type === AuctionStatusType.MINIMUM_PRICE
    ) {
      if (priceGreenCondition) {
        text = <p className="text-2xs m-0 bid-price-label__green">RESERVE MET</p>;
      } else {
        text = (
          <p
            className={`text-2xs m-0 ${
              item.type === AuctionStatusType.PHYSICAL
                ? 'bid-label-black-color'
                : 'bid-label-danger-color'
            } text-xs`}
          >
            {item.type === AuctionStatusType.PHYSICAL ? 'RESERVE NOT MET' : 'NO RESERVE SALE'}
          </p>
        );
      }
    }

    return text;
  };

  return (
    <>
      {!isDateBefore(item?.endsOn, new Date()) ||
      item.bids.some(item => item.bidderOrganizationId === organizationId) ? (
        <>
          {(() => {
            switch (item.status) {
              case AuctionStatus.PUBLISHED:
                return (
                  <>
                    {item.isBiddingAllowed ? (
                      <Row className="auction-place-bid-box d-flex justify-content-start">
                        <Formik
                          innerRef={bidFormRef}
                          enableReinitialize
                          initialValues={{ bid: '' }}
                          onSubmit={handleSubmit}
                          validationSchema={validationSchema}
                          validateOnChange
                          validateOnBlur={false}
                        >
                          {() => (
                            <Form className="auction-place-bid-box__form">
                              <Row className="m-0">
                                <Col
                                  md={12}
                                  className="auction-place-bid-box__form-inner-wrapper"
                                >
                                  <div className="pb-2">
                                    <p className="text-center mb-0 text-sm color-success-text">
                                      WIN THIS AUCTION
                                    </p>
                                    <p className="text-center font-weight-bold mb-0 text-sm color-success-text">
                                      PLACE YOUR BID
                                    </p>
                                  </div>
                                  <Row>
                                    <Col
                                      md={12}
                                      className="d-flex justify-content-center align-items-center flex-column"
                                    >
                                      <p className="text-xl font-weight-bold m-0">
                                        {preparePriceFormat(
                                          proxyBidInPence ||
                                            highestBidInPence ||
                                            (item.type !== AuctionStatusType.MINIMUM_PRICE
                                              ? 100
                                              : 0),
                                          false,
                                        )}
                                      </p>
                                      {getBidTitle()}
                                    </Col>
                                  </Row>
                                  <Row className="d-flex justify-content-center align-items-start pb-2">
                                    <Col md={12} className="p-0 pb-2">
                                      {bidsLeft > 0 && (
                                        <>
                                          <FormCurrencyInputField
                                            inputClassName="font-weight-bold"
                                            maxLength={14}
                                            name="bid"
                                            disabled={isLoading || bidsLeft === 0}
                                            placeholder={`Minimum ${preparePriceFormat(
                                              bidInitValue,
                                              false,
                                            )}`}
                                          />
                                          <CommonErrorLabel value={bidErrorMessage} />
                                        </>
                                      )}
                                    </Col>
                                    <Col md={12} className="p-0 pb-2">
                                      {bidsData.length > 0 && renderBidMessage()}
                                    </Col>
                                    <Col
                                      md={12}
                                      className="d-flex align-items-center align-self-center p-0 buyer-section__send-button-wrapper"
                                    >
                                      <CommonButton
                                        disabled={isLoading || bidsLeft <= 0}
                                        type="submit"
                                        label="PLACE BID"
                                        className="auction-place-bid-box__send-button text-sm"
                                        variant={CommonButtonVariants.SUCCESS}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </Row>
                    ) : (
                      ''
                    )}
                  </>
                );
              default:
                return null;
            }
          })()}
        </>
      ) : null}
      {showConfirm && (
        <SearchConfirmBidModal
          toggleShow={() => setShowConfirm(false)}
          placeBid={handleBid}
          bidAmount={bidToSend}
          vehicleTitle={getTitle(item) || ''}
        />
      )}
    </>
  );
};

PlaceBidBox.defaultProps = {
  bids: [],
  shouldPriceBeGreen: null,
};

PlaceBidBox.propTypes = {
  item: PropTypes.object.isRequired,
  refreshItem: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  bids: PropTypes.array,
  shouldPriceBeGreen: PropTypes.bool,
};

export default PlaceBidBox;
