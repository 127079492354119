import { Colors } from '../../constants/Colors';
import { AuctionStatus } from '../../constants/AuctionStatus';
import BoxStatusVariants from '../../constants/BoxStatusVariants';

export const getPriceColor = shouldPriceBeGreen => {
  if (!shouldPriceBeGreen) return null;
  return {
    color: Colors.onSaleColor,
  };
};

export const getVehicleLiveOfferStatusStyle = status => {
  switch (status) {
    case AuctionStatus.PUBLISHED:
      return BoxStatusVariants.LIVE;
    case AuctionStatus.DRAFT:
    case AuctionStatus.ENDED:
      return BoxStatusVariants.SECONDARY;
    case AuctionStatus.SOLD:
      return BoxStatusVariants.SUCCESS;
    case AuctionStatus.CANCELLED:
    case AuctionStatus.REJECTED:
      return BoxStatusVariants.DANGER;
    case AuctionStatus.TO_ACCEPT:
      return BoxStatusVariants.SUCCESS;
    case AuctionStatus.WAITING:
      return BoxStatusVariants.WARNING;
    default:
      return BoxStatusVariants.SUCCESS;
  }
};
