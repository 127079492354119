export const AuctionStatus = {
  CANCELLED: 'CANCELLED',
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  SOLD: 'SOLD',
  TO_ACCEPT: 'TO_ACCEPT',
  WAITING: 'WAITING',
  ENDED: 'ENDED',
};

export const AuctionStatusName = {
  PUBLISHED: 'Live',
  DRAFT: 'Parked',
  SOLD: 'Sold',
  TO_ACCEPT: 'To accept',
  WAITING: 'Waiting',
  REJECTED: 'Declined',
  CANCELLED: 'Cancelled',
  ENDED: 'Ended',
};

export const AuctionStatusColors = {
  CANCELLED: '#f46363',
  DRAFT: '#aaa7a4',
  PUBLISHED: '#174e86',
  REJECTED: '#cc2727',
  SOLD: '#297422',
  TO_ACCEPT_BUYER: '#55b84b',
  TO_ACCEPT_SELLER: '#fbc658',
  WAITING: '#fbc658',
  ENDED: '#b12983',
};

export const AuctionBarColors = {
  BLUE: '#2F5188',
  RED: '#AB3B66',
  STRONG_RED: '#EB002F',
  GREEN: '#00957B',
  GRAY: `#d0d5dd`,
  YELLOW: `#FFCA5B`,
};

export const AuctionStatusAnalyticsColors = {
  CANCELLED: '#EEB6CB',
  DRAFT: '#D0D5DD',
  PUBLISHED: '#00B394',
  REJECTED: '#D87FA1',
  TO_ACCEPT: '#9CB6DF',
  WAITING: '#FFCA5B',
  ENDED: '#667085',
};

export const AuctionStatusFromSse = {
  AUCTION_WAITING: 'WAITING',
  AUCTION_TO_ACCEPT: 'TO_ACCEPT',
  AUCTION_SOLD: 'SOLD',
  AUCTION_REJECTED: 'REJECTED',
  AUCTION_CANCELLED: 'CANCELLED',
  AUCTION_ENDED: 'ENDED',
};
