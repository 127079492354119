import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import VideoSnapshot from 'video-snapshot';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import { UploadFileTypes } from '../../constants/UploadFileTypes';
import {
  deleteVehicleVideoInSummary,
  getDraft,
  prepareThumbnailFile,
  saveAddPhotoDraft,
  sendVehicleVideoInSummary,
  // validateMedia,
} from '../AddVehicleActions';
import plusIcon from '../../assets/img/plus-icon.png';
import CommonDeleteIconButton from '../../common/components/CommonDeleteIconButton';
import AddVehicleFooter from '../AddVehicleFooter';

const AddVideoForm = ({ prevForm, nextForm }) => {
  const dispatch = useDispatch();
  const [validationError, setValidationError] = useState(false);
  const [isVideoLoading, setVideoLoading] = useState(false);
  const id = useSelector(state => state.addVehicle.get('id'));
  const videos = Array.from(useSelector(state => state.addVehicle.get('videos')));

  const handleRemoveVideoClick = id => {
    dispatch(deleteVehicleVideoInSummary(id));
  };

  const getThumbnail = async file => {
    const snapshoter = new VideoSnapshot(file);
    const snap = await snapshoter.takeSnapshot();
    // eslint-disable-next-line no-return-await
    return await prepareThumbnailFile(snap);
  };

  const handleUploadVideo = async e => {
    setVideoLoading(true);
    const file = e.target.files[0];
    const thumbnail = await getThumbnail(file);
    if (file && file?.type.includes(UploadFileTypes.VIDEO)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(
          sendVehicleVideoInSummary(file, thumbnail, data => {
            setVideoLoading(false);
            if (!data) {
              setValidationError(true);
            }
          }),
        );
      };
    }
  };

  const handleNextButton = () => {
    nextForm();
    // dispatch(validateMedia(() => nextForm()));
  };

  const handleBackButton = () => {
    prevForm();
  };

  function handleSaveDraftButton() {
    dispatch(saveAddPhotoDraft()).then(() => dispatch(getDraft(id)));
  }

  return (
    <>
      <AddVehicleHeader saveDraftButtonHandler={handleSaveDraftButton} />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Videos</h5>
            <p className="text-sm mt-2">
              You can add 2 videos of the vehicle to enhance your listing.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="photo-summary__videos-outer-wrapper">
              <Row className="photo-summary__videos-wrapper">
                {videos.length
                  ? videos.map((item, index) => (
                      <Col md={4} className="p-0" key={uuid()}>
                        <div
                          key={item.mediaMetadataId}
                          className="photo-summary__video-wrapper"
                        >
                          <div className="d-flex justify-content-center align-items-center w-100 h-100 position-relative">
                            <div className="overflow-hidden h-100 w-100">
                              <img
                                src={item.previewUrl}
                                alt="video"
                                className="photo-summary__video"
                              />
                            </div>
                          </div>
                          <Row className="mt-2">
                            <Col className="d-flex flex-row align-items-center">
                              <span className="photo-summary__add-video-title text-sm font-weight-bold">
                                {index === 0 ? 'EXTERIOR VIDEO' : 'INTERIOR VIDEO'}
                              </span>
                              <CommonDeleteIconButton
                                className="photo-summary__photo-icon-button ml-auto"
                                handleClick={() =>
                                  handleRemoveVideoClick(item.mediaMetadataId)
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ))
                  : null}
                {!videos.length || videos.length < 2 ? (
                  <Col md={4} className="p-0">
                    <div className="photo-summary__video-wrapper">
                      <button
                        type="button"
                        className="add-photo__base-style photo-summary__add-obligatory-video-button position-relative m-0"
                        disabled={isVideoLoading}
                      >
                        <label
                          htmlFor="video-upload"
                          className="photo-summary__add-video-upload-label"
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center w-100">
                            <p className="add-photo__drop-text m-0">CLICK TO BROWSE OR</p>
                            <p className="add-photo__drop-text m-0">DRAG & DROP VIDEO HERE</p>
                            <div className="add-photo__add_center-icon mt-3">
                              <img
                                src={plusIcon}
                                alt="Add"
                                className={`${
                                  isVideoLoading
                                    ? 'photo-summary__add-photo-upload-label--loading'
                                    : ''
                                }`}
                              />
                              {!isVideoLoading && (
                                <input
                                  id="video-upload"
                                  type="file"
                                  accept="video/mp4,video/x-m4v,video/*"
                                  onChange={handleUploadVideo}
                                />
                              )}
                            </div>
                          </div>
                        </label>
                      </button>
                      <Row>
                        <Col>
                          <p className="photo-summary__add-video-title mt-2 text-sm font-weight-bold">
                            {videos.length < 1 ? 'EXTERIOR VIDEO' : 'INTERIOR VIDEO'}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
            <Row>
              {validationError && (
                <label className="error-label mt-3">Upload only image/video format.</label>
              )}
            </Row>
          </Col>
        </Row>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={handleNextButton}
        backButtonHandler={handleBackButton}
        nextButtonDisabled={false}
      />
    </>
  );
};

AddVideoForm.propTypes = {
  prevForm: PropTypes.func.isRequired,
};

export default AddVideoForm;
