import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import _, { cloneDeep } from 'lodash';
import { reverse } from 'named-urls';
import { Col, Row, Alert } from 'reactstrap';
import InfinityLoader from '../common/components/InfinityLoader';
import { SSE_BUYER, SSEClient } from '../api/SSEClient';
import { clearMyBids, searchMyBids, setBidsFilter } from './MyBidsActions';
import MyBidsHeader from './MyBidsHeader';
import MyBidsSearch from './MyBidsSearch';
import SearchBidModal from '../search/bidModals/SearchBidModal';
import { placeBid } from '../search/SearchActions';
import MyBidsItem from './MyBidsItem';
import RouterPaths from '../constants/RouterPaths';
import { getAuction, handleAuctionEvent } from '../myvehicles/MyVehiclesActions';
import { MY_BIDS_REFRESH_SUCCESS } from './MyBidsReducer';
import {
  AccountLayoutNavItem,
  AccountLayoutTitle,
  AccountLayoutWrapper,
} from '../common/components/AccountLayoutWrapper';
import { BidStatusType } from '../constants/BidStatusType';

const sseClient = new SSEClient(SSE_BUYER);

const MyBidsPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const itemsRef = useRef({});
  const [scrollTo, setScrollTo] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [offset, setOffset] = useState(0);
  const [auctionsIds, setAuctionsIds] = useState([]);
  const [search, setSearch] = useState('');
  const [currentBidAuction, setCurrentBidAuction] = useState(null);
  const [showBidModal, setShowBidModal] = useState(false);
  const [bidsNotifications, setBidsNotifications] = useState({});

  const hasMore = useSelector(state => state.myBids.get('hasMore'));
  const bidsSearchFilter = useSelector(state => state.myBids.get('filter'));
  const searchQuery = useSelector(state => state.search.get('searchQuery'));
  const searchItems = useSelector(state => state.myBids.get('items').toJS());
  const auctionTimestamp = useSelector(state => state.myBids.get('timeStamp'));
  const searchTotalItems = useSelector(state => state.myBids.get('totalItems'));
  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const [hasAccess, setHasAccess] = useState(false);

  const updateSearchItems = newItem => {
    dispatch({
      type: MY_BIDS_REFRESH_SUCCESS,
      payload: {
        item: newItem,
      },
    });
  };

  useEffect(() => {
    const { hash } = location;
    if (hash && hash === '#bidsFilter') {
      history.replace(RouterPaths.MY_BIDS);
    } else {
      dispatch(setBidsFilter(bidsSearchFilter));
    }

    return () => {
      dispatch(setBidsFilter(BidStatusType.PLACED_BIDS)); // reset to first filter
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isBuyer) {
      setHasAccess(false);
      if (isSeller) {
        return history.push(RouterPaths.MY_VEHICLES);
      }

      return history.push(RouterPaths.SEARCH);
    }
    return setHasAccess(true);
  }, [isBuyer, isSeller, history]);

  useEffect(() => {
    sseClient.listen(auctionsIds, response => {
      dispatch(
        handleAuctionEvent(response, () =>
          dispatch(
            getAuction(response.auctionId, auction => {
              updateSearchItems(auction);
            }),
          ),
        ),
      );
    });
    return () => {
      sseClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionsIds, dispatch]);

  const scrollToItem = () => {
    if (!scrollTo) return;
    if (itemsRef.current[scrollTo]) {
      itemsRef.current[scrollTo].scrollIntoView({ behavior: 'auto', block: 'center' });
      setScrollTo(null);
    }
  };

  useEffect(() => {
    if (location.state && location.state.offset && location.state.auctionId) {
      setOffset(_.get(location, 'state.offset', 0) + 1);
      setScrollTo(location.state.auctionId);
      history.replace({ ...history.location, state: {} });
      return;
    }
    dispatch(
      searchMyBids(
        {
          search,
          bidsSearchFilter,
          offset,
        },
        auctionsIds => {
          setAuctionsIds(auctionsIds);
          setLoaded(true);
          scrollToItem();
        },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, bidsSearchFilter, search, dispatch]);

  const loadData = () => {
    setOffset(prev => prev + 1);
  };

  const clearResults = () => {
    dispatch(clearMyBids());
    setOffset(0);
    setAuctionsIds([]);
    setLoaded(false);
  };

  const handleFilter = status => {
    clearResults();
    dispatch(setBidsFilter(status));
  };

  const handleSearch = value => {
    if (search !== value) {
      clearResults();
    }
    setSearch(value);
  };

  const handleBidModal = item => {
    setCurrentBidAuction(item);
    setShowBidModal(true);
  };

  const toggleBidModal = () => {
    setShowBidModal(false);
  };

  const addNotification = (id, message, className) => {
    const notifications = cloneDeep(bidsNotifications);
    notifications[id] = {
      className,
      message,
    };
    setBidsNotifications(notifications);
  };

  const handlePlaceBid = amount => {
    dispatch(
      placeBid(currentBidAuction?.id, amount * 100, response => {
        const { success, auctionId, result } = response;
        if (!success) {
          addNotification(auctionId, result, 'warning');
        } else {
          let message;

          const bidAmount = `£${amount.toLocaleString('en-GB')}`;

          if (result.isHighestBid) {
            if (result.proxyBidInPence) {
              message = `Your bid of ${result.proxyBidInPence} is the highest. Your maximum bid is ${bidAmount}`;
            } else {
              message = `Your bid of ${bidAmount} is the highest`;
            }
          } else {
            message = `Your bid of ${bidAmount} is not the highest - try again`;
          }
          addNotification(auctionId, message, 'warning');
        }
      }),
    );
  };

  const setRef = (ref, id) => {
    itemsRef.current[id] = ref;
  };

  const navItems = useCallback(() => {
    return [
      <AccountLayoutNavItem link={RouterPaths.MY_VEHICLES} hide={!isSeller}>
        SELLING
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem link={RouterPaths.MY_BIDS} hide={!hasAccess}>
        BUYING
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem link={RouterPaths.ANALYTICS}>ANALYTICS</AccountLayoutNavItem>,
    ];
  }, [isSeller, hasAccess]);

  return (
    <AccountLayoutWrapper
      contentClassName="content-column__listing"
      navTitle={
        <AccountLayoutTitle>
          {searchTotalItems} {`${searchTotalItems === 1 ? 'VEHICLE' : 'VEHICLES'}`} BUYING
        </AccountLayoutTitle>
      }
      navOptions={navItems()}
    >
      <>
        {hasAccess && (
          <>
            <Row className="auction-detail-header">
              <Col md={12} className="p-0 d-flex flex-row">
                <h4 className="m-0 font-weight-bold page-title">Buying</h4>
                <div className="ml-auto d-flex flex-row auction-detail-header__actions-container">
                  <MyBidsSearch handleSearch={handleSearch} />
                </div>
              </Col>
            </Row>
            <MyBidsHeader handlerChange={handleFilter} />
            {loaded && (
              <>
                {searchTotalItems > 0 ? (
                  <InfiniteScroll
                    next={loadData}
                    hasMore={hasMore}
                    loader={<InfinityLoader />}
                    dataLength={searchItems.length}
                  >
                    {searchItems.map(item => (
                      <MyBidsItem
                        getRef={el => setRef(el, item.id)}
                        item={item}
                        key={item.id}
                        handleBid={handleBidModal}
                        auctionTimestamp={auctionTimestamp}
                        onClickItem={() =>
                          history.push({
                            pathname: reverse(RouterPaths.MY_BIDS_DETAILS, { id: item.id }),
                            state: {
                              offset,
                            },
                          })
                        }
                      />
                    ))}
                  </InfiniteScroll>
                ) : (
                  <div className="mt-2">
                    {searchQuery ? (
                      <Alert color="info">
                        Oops, we could not find any result for the given phrase
                      </Alert>
                    ) : (
                      <Alert color="info">There are no bids</Alert>
                    )}
                  </div>
                )}
              </>
            )}
            {showBidModal && (
              <SearchBidModal
                toggleShow={toggleBidModal}
                item={currentBidAuction}
                handlePlaceBid={handlePlaceBid}
              />
            )}
          </>
        )}
      </>
    </AccountLayoutWrapper>
  );
};
export default MyBidsPage;
