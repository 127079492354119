import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import RouterPaths from '../../constants/RouterPaths';
import logo from '../../assets/img/logo-itrade-new.png';
import CommonButton from '../../common/components/CommonButton';

const ConfirmForgotPasswordPage = () => {
  const history = useHistory();

  return (
    <Row className="auth-page m-0 p-0">
      <Col className="auth-page__left-column" md={3} sm={12}>
        <Link to={RouterPaths.HOMEPAGE} className="page-logo-link">
          <img src={logo} alt="logo" />
        </Link>
        <h2 className="auth-page__big-title font-weight-bold auth-page__hide-on-mobile">
          Lobortis libero vitae aliquam sed.
        </h2>
        <p className="auth-page__hide-on-mobile">
          Lectus nisl, eget congue viverra sed. Bibendum neque elementum.
        </p>
      </Col>
      <Col className="auth-page__right-column" md={9} sm={12}>
        <h4 className="mt-0 text-center">Reset password confirmation</h4>
        <p className="text-center">
          A message with further instructions has been sent to your email address.
        </p>
        <Row className="mt-3">
          <Col>
            <CommonButton
              label="GO BACK TO HOMEPAGE"
              handleClick={() => {
                history.push(RouterPaths.HOMEPAGE);
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ConfirmForgotPasswordPage;
