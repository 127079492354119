import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { isEmpty } from 'lodash';
import { Col, Row } from 'reactstrap';
import uuid from 'react-uuid';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BidStatus } from 'itrade-admin-panel/src/constants/BidStatus';
import { AuctionStatusType } from '../../../constants/AuctionStatusType';
import {
  AuctionBarColors,
  AuctionStatus,
  AuctionStatusFromSse,
  AuctionStatusName,
} from '../../../constants/AuctionStatus';
import RouterPaths from '../../../constants/RouterPaths';
import AuctionDetailsSellerSection from '../AuctionDetailsSellerSection';
import { auctionListType } from '../../../constants/AuctionListType';
import VehicleImagesPopupSlider from '../VehicleImagesPopupSlider';
import AuctionDetailsBuyerSection from '../AuctionDetailsBuyerSection';
import magnifierServiceIcon from '../../../assets/img/magnifierServiceIcon.png';
import tireIcon from '../../../assets/img/tireIcon.png';
import { UploadFileTypes } from '../../../constants/UploadFileTypes';
import { dateToString } from '../../utils/date/Date.utils';
import { preparePriceFormat } from '../../helpers/preparePriceFormat';
import {
  AUCTION_CANCELLED,
  AUCTION_UPDATED,
  BID_PLACED,
  SSE_BUYER,
  SSE_SELLER,
} from '../../../api/SSEClient';
import { CommonString } from '../../../constants/CommonString';
import { capitalizeString } from '../../helpers/capitalizeString';
import { sortedByPerspective } from '../../../addvehicle/helpers/sortByPerspective';
import { setRelaunchAllow } from '../../../myvehicles/MyVehiclesActions';
import { BasicCarDataFieldsName } from '../../../constants/BasicCarDataFields';
import { pencesToPounds, poundsToPences } from '../../helpers/pencesToPounds';
// import AuctionTypeLabel from '../../../myvehicles/AuctionTypeLabel';
import AuctionDetailsHeader from './AuctionDetailsHeader';
import AuctionBuyerInformation from './AuctionBuyerInformation';
import AuctionMainDescriptionContainer, { SlotItems } from './AuctionMainDescriptionContainer';
import {
  AccountLayoutNavItem,
  AccountLayoutTitle,
  AccountLayoutWrapper,
} from '../AccountLayoutWrapper';
import PlaceBidBox from './PlaceBidBox';
import AuctionDealerInformation from './AuctionDealerInformation';
import { prepareBidsData } from '../../helpers/prepareBidsData';
import { getBidItemMessage } from '../../helpers/auctionItemHelper';
import BoxStatusVariants from '../../../constants/BoxStatusVariants';
import AuctionStatusBox from '../../../myvehicles/AuctionStatusBox';
import { scrollToElement } from '../../helpers/generalHelper';
import { prepareVehicleTitle } from '../../helpers/prepareVehicleTitle';
import ImagesSlider from './ImagesSlider';
import { shortMeText } from '../../helpers/shortMeText';

const {
  REGISTRATION_PLATE,
  MILEAGE,
  YEAR,
  MOT,
  CAR_TYPE,
  TRANSMISSION,
  ENGINE_SIZE,
  FUEL,
  COLOUR,
  DATE_OFF_FIRST_REGISTRATION,
  PREVIOUS_KEEPERS_IN_TOTAL,
  VIN,
  LOCATION,
  MAKE,
  MODEL,
  DERIVATIVE,
} = BasicCarDataFieldsName;

const AuctionDetailsItem = ({
  item,
  listType,
  refreshItem,
  sseClient,
  typeSseClient,
  nextStep,
  setIsRepublish,
  inWrapper,
  wrapperProps,
  auctionTimestamp,
  location,
}) => {
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const currentMemberId = useSelector(state => state.auth.get('id'));

  const [showPopupServiceHistorySlider, setShowPopupServiceHistorySlider] = useState(false);
  const [clickedPhoto, setClickedPhoto] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [shouldPriceBeGreen, setShouldPriceBeGreen] = useState(false);
  const [highestBid, setHighestBid] = useState(null);
  const [proxyBid, setProxyBid] = useState(null);
  const [bidNotification, setBidNotification] = useState();
  const [bidsNotification, setBidsNotification] = useState();
  const [status, setStatus] = useState(false);
  const [isAuctionRelaunched, setIsAuctionRelaunched] = useState(false);
  const [windowFocus, setWindowFocus] = useState(false);
  const dispatch = useDispatch();

  const bidsData = useMemo(() => {
    return bidsNotification && bidsNotification.length > 0 ? bidsNotification : item?.bids;
  }, [bidsNotification, item]);

  const {
    bidMyOrganizationIsHighest,
    highestBidMyOrganization,
    bidsMyOrganization,
    winningBid,
  } = useMemo(() => {
    return prepareBidsData(bidsData, organizationId);
  }, [bidsData, organizationId]);

  const windowOnFocus = () => {
    setWindowFocus(true);
  };

  const windowOnBlur = () => {
    setWindowFocus(false);
  };

  const onNavItemClick = (e, id, offset = -120) => {
    e.preventDefault();
    scrollToElement(id, offset);
  };

  const navItems = useCallback(() => {
    const winSectionIsVisible = canShowAuctionWinInformation();

    return [
      <AccountLayoutNavItem
        link="#vehicle-details-info"
        onClick={e => onNavItemClick(e, 'vehicle-details-info', -400)}
        className="scroller"
      >
        PHOTOS & INFO
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem
        link="#vehicle-selling-person-info"
        onClick={e => onNavItemClick(e, 'vehicle-selling-person-info')}
        className="scroller"
        hide={!winSectionIsVisible}
      >
        SELLING PERSON INFO
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem
        link="#vehicle-location"
        onClick={e => onNavItemClick(e, 'vehicle-location')}
        className="scroller"
        hide={!winSectionIsVisible}
      >
        VEHICLE LOCATION
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem
        link="#vehicle-sellers-information"
        onClick={e => onNavItemClick(e, 'vehicle-sellers-information')}
        className="scroller"
        hide={!winSectionIsVisible}
      >
        SELLERS INFORMATION
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem
        link="#vehicle-buyers-information"
        onClick={e => onNavItemClick(e, 'vehicle-buyers-information')}
        className="scroller"
        hide={!canShowBuyersInformation()}
      >
        BUYERS INFORMATION
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem
        link="#vehicle-your-bids-history"
        onClick={e => onNavItemClick(e, 'vehicle-your-bids-history')}
        className="scroller"
        hide={!canShowBidsMyOrganisation()}
      >
        YOUR BIDS HISTORY
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem
        link="#vehicle-details-specification"
        onClick={e => onNavItemClick(e, 'vehicle-details-specification')}
        className="scroller"
      >
        SPECIFICATION
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem
        link="#vehicle-details-service-history"
        onClick={e => onNavItemClick(e, 'vehicle-details-service-history')}
        className="scroller"
      >
        SERVICE HISTORY
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem
        link="#vehicle-details-description"
        onClick={e => onNavItemClick(e, 'vehicle-details-description')}
        className="scroller"
      >
        DESCRIPTION
      </AccountLayoutNavItem>,
    ];
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('focus', windowOnFocus);
    window.addEventListener('blur', windowOnBlur);
    return () => {
      window.removeEventListener('focus', windowOnFocus);
      window.removeEventListener('blur', windowOnBlur);
    };
  }, []);

  useEffect(() => {
    setHighestBid(pencesToPounds(item?.highestBidInPence));
    setShouldPriceBeGreen(shouldPriceBeGreen || item?.shouldPriceBeGreen);
    sseClient.listen([item.id], response => {
      setStatus(!!AuctionStatusFromSse[response.type]);
      if (response.type === BID_PLACED || response.type === AUCTION_UPDATED) {
        handleBidEvent(response);
      }
      if (response?.type === AUCTION_CANCELLED) {
        dispatch(setRelaunchAllow(true));
      }
      refreshItem();
    });
    return () => {
      sseClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id]);

  const changeStatus = data => {
    setStatus(data);
  };

  const isPaymentProgress = useCallback(() => {
    return (
      !item?.seller?.firstName &&
      !item?.location?.address &&
      !item?.seller?.organizationContact?.tradeName
    );
  }, [item]);

  const handleBidEvent = event => {
    setIsAuctionRelaunched(false);
    setShouldPriceBeGreen(shouldPriceBeGreen || event.shouldPriceBeGreen);
    setHighestBid(pencesToPounds(event.highestBidInPence));
    setProxyBid(pencesToPounds(event.proxyBidInPence));
    if (typeSseClient === SSE_SELLER) {
      if (!event.highestBids.length) {
        return false;
      }
      const highestAmountBid = event.highestBids.reduce((acc, current) => {
        if (current.amountInPence > acc.amountInPence) {
          return current;
        }
        return acc;
      });
      if (highestAmountBid) {
        const bidAmount = preparePriceFormat(highestAmountBid.amountInPence, true);
        setBidNotification(bidAmount);
      }
    }
    if (typeSseClient === SSE_BUYER) {
      setBidsNotification(event.highestBids);
    }
    return true;
  };

  useEffect(() => {
    if (!isEmpty(item?.vehicle?.vehicleMedia?.photos)) {
      const preparedPhotos = item?.vehicle?.vehicleMedia?.photos.map(item => {
        if (item?.fileUrl) {
          return {
            id: item?.mediaMetadataId,
            fileUrl: item?.fileUrl,
            perspective: item?.perspective,
            damages: item?.damages
              ? item.damages.map(damage => {
                  return {
                    id: damage?.mediaMetadataId,
                    fileUrl: damage?.fileUrl,
                    damageType: damage?.damageType,
                  };
                })
              : [],
          };
        }
        return null;
      });

      setPhotos(sortedByPerspective(preparedPhotos));
    }
    if (!isEmpty(item?.vehicle?.vehicleMedia?.videos)) {
      const preparedVideo = item?.vehicle?.vehicleMedia?.videos.map(item => {
        if (item.fileUrl) {
          return {
            fileUrl: item.fileUrl,
            id: item?.mediaMetadataId,
            previewId: item?.previewMediaMetadataId,
            previewUrl: item?.previewUrl,
            type: UploadFileTypes.VIDEO_TYPE,
          };
        }
        return null;
      });
      setVideos(preparedVideo);
    }
  }, [item]);

  const serviceHistoryThumbnailUrls =
    item?.vehicle?.serviceHistory?.photoRecords &&
    item?.vehicle?.serviceHistory?.photoRecords.map(item => item.fileUrl);

  const renderLocationValue = () => {
    if (item?.location?.city) {
      if (item?.location?.county) {
        return `${item?.location?.city}, ${item?.location?.county}`;
      }
      return `${item?.location?.city}`;
    }
    return CommonString.NOT_SPECIFIED;
  };

  const carInfo = [
    {
      title: MAKE,
      value: item?.vehicle?.vehicleModel?.makeName || CommonString.NOT_SPECIFIED,
    },
    {
      title: MODEL,
      value: item?.vehicle?.vehicleModel?.name || CommonString.NOT_SPECIFIED,
    },
    {
      title: DERIVATIVE,
      value: shortMeText(item?.vehicle?.derivative || CommonString.NOT_SPECIFIED, 15),
    },
    {
      title: REGISTRATION_PLATE,
      value: item?.vehicle?.registrationPlate || CommonString.NOT_SPECIFIED,
    },
    {
      title: MILEAGE,
      value: item?.vehicle?.mileage
        ? `${item?.vehicle?.mileage} miles`
        : CommonString.NOT_SPECIFIED,
    },
    {
      title: TRANSMISSION,
      value: capitalizeString(item?.vehicle?.transmission) || CommonString.NOT_SPECIFIED,
    },
    {
      title: CAR_TYPE,
      value: capitalizeString(item?.vehicle?.carType) || CommonString.NOT_SPECIFIED,
    },
    {
      title: COLOUR,
      value: capitalizeString(item?.vehicle?.colour) || CommonString.NOT_SPECIFIED,
    },
    {
      title: YEAR,
      value: item?.vehicle?.year || CommonString.NOT_SPECIFIED,
    },
    {
      title: ENGINE_SIZE,
      value: item?.vehicle?.engineSize || CommonString.NOT_SPECIFIED,
    },
    {
      title: FUEL,
      value: item?.vehicle?.fuel || CommonString.NOT_SPECIFIED,
    },
    {
      title: DATE_OFF_FIRST_REGISTRATION,
      value:
        dateToString(item?.vehicle?.dateOfFirstRegistration) || CommonString.NOT_SPECIFIED,
    },
    {
      title: PREVIOUS_KEEPERS_IN_TOTAL,
      value:
        item?.vehicle?.previousKeepersInTotal !== undefined &&
        item?.vehicle?.previousKeepersInTotal !== null
          ? item?.vehicle?.previousKeepersInTotal
          : CommonString.NOT_SPECIFIED,
    },
    {
      title: MOT,
      value: dateToString(item?.vehicle?.mot) || CommonString.NOT_SPECIFIED,
    },
    {
      title: VIN,
      value: item?.vehicle?.vin
        ? item?.vehicle?.vin?.toUpperCase()
        : CommonString.NOT_SPECIFIED,
    },
    {
      title: LOCATION,
      value: renderLocationValue() || CommonString.NOT_SPECIFIED,
    },
  ];

  const minimumPrice = pencesToPounds(item?.minimumPriceInPence);

  // eslint-disable-next-line no-unused-vars
  let backLinkUrl;
  // eslint-disable-next-line no-unused-vars
  let backLinkText;

  switch (listType) {
    case auctionListType.MY_VEHICLES:
      backLinkUrl = RouterPaths.MY_VEHICLES;
      backLinkText = 'Back to "Selling"';
      break;
    case auctionListType.MY_BIDS:
      backLinkUrl = RouterPaths.MY_BIDS;
      backLinkText = 'Back to "Buying"';
      break;
    case auctionListType.SEARCH:
    default:
      // eslint-disable-next-line no-unused-vars
      backLinkUrl = RouterPaths.SEARCH;
      // eslint-disable-next-line no-unused-vars
      backLinkText = 'Back to the search results';
      break;
  }

  // eslint-disable-next-line no-unused-vars
  const renderDetailsSection = () => {
    if (
      listType === auctionListType.MY_BIDS ||
      (listType === auctionListType.SEARCH &&
        item?.seller?.organizationContact?.id !== organizationId)
    ) {
      return (
        <AuctionDetailsBuyerSection
          highestBidInPence={poundsToPences(highestBid) || minimumPrice || 0}
          item={item}
          refreshItem={refreshItem}
          bids={bidsNotification}
          changeStatus={changeStatus}
          status={status}
        />
      );
    }
    return (
      <AuctionDetailsSellerSection
        item={item}
        refreshItem={refreshItem}
        bidNotification={bidNotification}
        changeStatus={changeStatus}
        status={status}
        isAuctionRelaunched={isAuctionRelaunched}
        setIsAuctionRelaunched={setIsAuctionRelaunched}
        nextStep={nextStep}
        setIsRepublish={setIsRepublish}
      />
    );
  };

  // const isAuctionLabel = useMemo(() => {
  //   return !!(item?.type || AuctionStatusTypeName[item?.type]);
  // }, [item.type]);

  const getAuctionBidMessage = useCallback(() => {
    const mapBidColorToBoxStatus = color => {
      switch (color) {
        case AuctionBarColors.RED:
        case AuctionBarColors.STRONG_RED:
          return BoxStatusVariants.RED;
        case AuctionBarColors.GREEN:
          return BoxStatusVariants.GREEN;
        case AuctionBarColors.BLUE:
          return BoxStatusVariants.PRIMARY;
        default:
          return BoxStatusVariants.PRIMARY;
      }
    };

    if (bidsMyOrganization?.length) {
      const messageData = getBidItemMessage(
        bidMyOrganizationIsHighest,
        highestBidMyOrganization?.amountInPence,
        item,
        winningBid?.bidderId === currentMemberId,
      );

      return messageData
        ? {
            bidBarMessage: messageData.message,
            bidBarColor: mapBidColorToBoxStatus(messageData.statusColor),
          }
        : null;
    }

    return null;
  }, [item, bidMyOrganizationIsHighest, highestBidMyOrganization, bidsMyOrganization]);

  const { bidBarMessage, bidBarColor } = getAuctionBidMessage() || {};

  const canShowAuctionWinInformation = () => {
    return (
      listType === auctionListType.MY_BIDS &&
      item?.status === AuctionStatus.SOLD &&
      !isPaymentProgress() &&
      bidMyOrganizationIsHighest
    );
  };

  const canShowBidsMyOrganisation = () => {
    return (
      (listType === auctionListType.MY_BIDS || listType === auctionListType.SEARCH) &&
      bidsMyOrganization?.length
    );
  };

  const canShowBuyersInformation = () => {
    return (
      listType === auctionListType.MY_VEHICLES &&
      item?.buyer?.organizationContact?.tradeName &&
      item.status === AuctionStatus.SOLD
    );
  };

  const VehicleDescriptionContent = () => {
    if (!item?.vehicle?.description && !item?.vehicle?.defaultDescription)
      return CommonString.DESCRIPTION_NOT_SPECIFIED;

    if (item?.vehicle?.defaultDescription) {
      return (
        <>
          {item?.vehicle?.description || ''}
          <br />
          {item.vehicle.defaultDescription}
        </>
      );
    }

    return item.vehicle.description;
  };

  const render = () => {
    return (
      <>
        <AuctionDetailsHeader
          nextStep={nextStep}
          item={item}
          setIsRepublish={setIsRepublish}
          setIsAuctionRelaunched={setIsAuctionRelaunched}
          refreshItem={refreshItem}
          listType={listType}
          location={location}
        />
        <Row className="m-0" md={12}>
          <Col className="p-0">
            <h4 className="font-weight-bold text-2xl mt-3">
              {prepareVehicleTitle(item?.vehicle) || CommonString.VEHICLE_NOT_SPECIFIED}
            </h4>
          </Col>
        </Row>
        <Row className="m-0">
          {!isEmpty(photos || videos) ? (
            <Col md={12} className="p-0">
              <ImagesSlider images={photos} videos={videos} />
            </Col>
          ) : (
            ''
          )}
        </Row>
        <Row
          className="auction-details-section summary__main-description-container d-flex m-0"
          id="vehicle-details-info"
        >
          <Col>
            {/* <Row md={12}> */}
            {/*  <Col className="d-flex flex-row p-0"> */}
            {/*    <div */}
            {/*      className={`main-description-container__type-label text-uppercase ${ */}
            {/*        isAuctionLabel ? '' : 'hide' */}
            {/*      }`} */}
            {/*    > */}
            {/*      <AuctionTypeLabel hasBid={!!item?.highestBidInPence} type={item.type} /> */}
            {/*    </div> */}
            {/*  </Col> */}
            {/* </Row> */}
            <Row>
              <AuctionMainDescriptionContainer
                item={item}
                windowFocus={windowFocus}
                organizationId={organizationId}
                listType={listType}
                isDetailsPage={true}
                pattern={[
                  SlotItems.CURRENT_BID,
                  SlotItems.TIME_LEFT,
                  SlotItems.AUCTION_END,
                  SlotItems.AUCTION_STATUS_BOX,
                ]}
                bidBarMessage={bidBarMessage}
                bidBarColor={bidBarColor}
                auctionTimestamp={auctionTimestamp}
                highestBid={highestBid}
                proxyBid={proxyBid}
                shouldPriceBeGreen={shouldPriceBeGreen}
              />
            </Row>

            {canShowAuctionWinInformation() ? <AuctionDealerInformation item={item} /> : ''}

            {canShowBuyersInformation() ? <AuctionBuyerInformation item={item} /> : ''}

            <Row className="main-description-container__section-title mt-4">
              <Col md={12} className="p-0 pb-2 text-lg">
                Vehicle Information
              </Col>
            </Row>
            <Row className="summary__feature-list mt-4" md={5} sm={3} xs={1}>
              {carInfo &&
                carInfo.map(item => (
                  <>
                    <Col key={item.title} className="p-0">
                      <div className="summary__list-item text-base">
                        <p className="m-0">{item.title}</p>
                        <p className="fw-600 m-0">{item.value}</p>
                      </div>
                    </Col>
                  </>
                ))}
            </Row>
          </Col>
        </Row>
        <Row
          className="main-description-container__section-title m-0 mt-4"
          id="vehicle-details-specification"
        >
          <Col md={12} className="p-0 pb-2 text-lg">
            Specification
          </Col>
        </Row>
        {(!item?.vehicle?.vehicleFeaturesCustom ||
          item?.vehicle?.vehicleFeaturesCustom.length === 0) &&
        (!item?.vehicle?.vehicleFeaturesDictionary ||
          item?.vehicle?.vehicleFeaturesDictionary.length === 0) ? (
          <Row className="summary__car-features_not_specified m-0 mt-4">
            <p className="summary__description-paragraph text-base">
              {CommonString.FEATURED_NOT_SPECIFIED}
            </p>
          </Row>
        ) : (
          <Row className="summary__car-features m-0 ">
            {item?.vehicle?.vehicleFeaturesDictionary &&
              item?.vehicle?.vehicleFeaturesDictionary.map(feature => (
                <div className="summary__feature-pills" key={feature.id}>
                  <span className="summary__feature-pills-name">{feature.value}</span>
                </div>
              ))}
            {item?.vehicle?.vehicleFeaturesCustom &&
              item?.vehicle?.vehicleFeaturesCustom.map(feature => (
                <div className="summary__feature-pills" key={uuid()}>
                  <span className="summary__feature-pills-name">{feature}</span>
                </div>
              ))}
          </Row>
        )}
        <Row
          className="main-description-container__section-title m-0 mt-4"
          id="vehicle-details-description"
        >
          <Col md={12} className="p-0 pb-2 text-lg">
            Description
          </Col>
        </Row>
        <Row className="summary__description mt-3 text-base">
          <Col md={12}>
            <VehicleDescriptionContent />
          </Col>
        </Row>
        {!item?.vehicle?.serviceHistory?.manualRecords?.length &&
        serviceHistoryThumbnailUrls?.length ? (
          ''
        ) : (
          <>
            <Row
              className="main-description-container__section-title m-0 mt-4"
              id="vehicle-details-service-history"
            >
              <Col md={12} className="p-0 pb-2 text-lg">
                Service History
              </Col>
            </Row>
            <Row className="m-0">
              <Col md={12} className="p-0">
                {!item?.vehicle?.serviceHistory?.manualRecords?.length ? (
                  <p className="summary__description-paragraph mt-3 text-base">
                    {CommonString.HISTORY_NOT_SPECIFIED}
                  </p>
                ) : (
                  <>
                    {item?.vehicle?.serviceHistory?.manualRecords.map(item => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Row className="summary__service-list-item text-base">
                        <Col sm={12} md={4} className="p-0">
                          <p className="m-0">
                            <b>Date</b>
                          </p>
                          <p className="m-0">{dateToString(item.date)}</p>
                        </Col>
                        <Col sm={12} md={4} className="p-0">
                          <p className="m-0">
                            <b>Mileage</b>
                          </p>
                          <p className="m-0">{item.mileage}</p>
                        </Col>
                        <Col sm={12} md={4} className="p-0">
                          <p className="m-0">
                            <b>Service Name</b>
                          </p>
                          <p className="m-0">{item.serviceName}</p>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        <Row
          className={`main-description-container__section-title m-0 mt-4 ${
            !serviceHistoryThumbnailUrls?.length ? 'hide' : ''
          }`}
        >
          <Col md={12} className="p-0 pb-2 text-lg">
            Service Book Scans
          </Col>
        </Row>
        <Row className={`m-0 mt-4 ${!serviceHistoryThumbnailUrls?.length ? 'hide' : ''}`}>
          <Col md={12}>
            <Row className="summary__service-history-list d-flex">
              {serviceHistoryThumbnailUrls.map((item, index) => {
                return (
                  <Col sm={12} md={3} className="summary__service-history-list-item">
                    <button
                      type="button"
                      className="summary__service-history-list-item-photo"
                      onClick={() => {
                        setClickedPhoto(index);
                        setShowPopupServiceHistorySlider(true);
                      }}
                    >
                      <img
                        className="summary__service-history-list-item-photo"
                        src={item}
                        alt="Vehicle"
                      />
                      <img
                        src={magnifierServiceIcon}
                        alt="Magnifier icon"
                        className="summary__service-history-list-item-icon"
                      />
                    </button>
                  </Col>
                );
              })}
            </Row>
            <SweetAlert
              custom
              show={showPopupServiceHistorySlider}
              onCancel={() => {
                setShowPopupServiceHistorySlider(false);
              }}
              onConfirm={() => {}}
              showCancel={false}
              showConfirm={false}
              title=""
              customClass="photo_gallery_popup"
            >
              <VehicleImagesPopupSlider
                photos={serviceHistoryThumbnailUrls.map(item => ({
                  id: item,
                  fileUrl: item,
                }))}
                initial={clickedPhoto}
                closeHandler={() => setShowPopupServiceHistorySlider(false)}
              />
            </SweetAlert>
          </Col>
        </Row>
        <Row className="main-description-container__section-title m-0 mt-4">
          <Col md={12} className="p-0 pb-2 text-lg">
            Tyre Tread Depths
          </Col>
        </Row>
        <Row className="summary__services-and-tyres-wrapper text-base m-0 mt-3">
          <Col md={12} className="p-0">
            <Row className="m-0 mt-2">
              <Col sm={12} md={3} className="p-0">
                <img
                  src={tireIcon}
                  width="30px"
                  alt="Tyre Tread Depth"
                  className="float-left mr-3"
                />
                <p className="m-0">Nearside Front</p>
                <p className="m-0 fw-600">
                  {item?.vehicle?.tireNearsideFrontMm
                    ? `${item?.vehicle?.tireNearsideFrontMm} mm`
                    : '-'}
                </p>
              </Col>
              <Col sm={12} md={3} className="p-0">
                <img
                  src={tireIcon}
                  width="30px"
                  alt="Tyre Tread Depth"
                  className="float-left mr-3"
                />
                <p className="m-0">Offside Front</p>
                <p className="m-0 fw-600">
                  {item?.vehicle?.tireOffsideFrontMm
                    ? `${item?.vehicle?.tireOffsideFrontMm} mm`
                    : '-'}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={12} className="p-0">
            <Row className="m-0 mt-2">
              <Col sm={12} md={3} className="p-0">
                <img
                  src={tireIcon}
                  width="30px"
                  alt="Tyre Tread Depth"
                  className="float-left mr-3"
                />
                <p className="m-0">Nearside Rear</p>
                <p className="m-0 fw-600">
                  {item?.vehicle?.tireNearsideRearMm
                    ? `${item?.vehicle?.tireNearsideRearMm} mm`
                    : '-'}
                </p>
              </Col>
              <Col sm={12} md={3} className="p-0">
                <img
                  src={tireIcon}
                  width="30px"
                  alt="Tyre Tread Depth"
                  className="float-left mr-3"
                />
                <p className="m-0">Offside Rear</p>
                <p className="m-0 fw-600">
                  {item?.vehicle?.tireOffsideRearMm
                    ? `${item?.vehicle?.tireOffsideRearMm} mm`
                    : '-'}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const isWonBidder = winningBid?.bidderId === currentMemberId;

  const isLostBidder =
    (item?.type === AuctionStatusType.TRYING_TO_DEAL || item?.status === AuctionStatus.SOLD) &&
    item?.status !== AuctionStatus.PUBLISHED &&
    item?.seller?.id !== currentMemberId &&
    item?.bids.length > 0 &&
    item?.bids?.filter(it => it.isHighest).length === 0;

  const isFinishedAuction =
    item?.type === AuctionStatusType.TRYING_TO_DEAL &&
    item?.status !== AuctionStatus.PUBLISHED &&
    item?.status !== AuctionStatus.SOLD &&
    item?.seller?.id !== currentMemberId &&
    item?.bids.length === 0;

  const renderBoxVariant = () => {
    if (isFinishedAuction) {
      return BoxStatusVariants.SECONDARY;
    }
    if (isLostBidder) {
      return BoxStatusVariants.DANGER;
    }
    if (item?.status === AuctionStatus.TO_ACCEPT || item?.status === AuctionStatus.WAITING) {
      return (isWonBidder && item?.isBuyerAcceptanceAllowed) ||
        (item?.seller?.id === currentMemberId && item?.isSellerAcceptanceAllowed)
        ? BoxStatusVariants.SUCCESS
        : BoxStatusVariants.WARNING;
    }
    return null;
  };

  const renderStatusBoxContent = () => {
    if (isFinishedAuction) {
      return AuctionStatusName.ENDED;
    }
    if (isLostBidder) {
      return BidStatus.LOST;
    }
    if (
      isWonBidder &&
      (item?.status === AuctionStatus.TO_ACCEPT || item?.status === AuctionStatus.WAITING)
    ) {
      return item?.isBuyerAcceptanceAllowed
        ? AuctionStatusName.TO_ACCEPT
        : AuctionStatusName.WAITING;
    }
    return null;
  };

  return inWrapper ? (
    <AccountLayoutWrapper
      {...wrapperProps}
      navOptions={wrapperProps?.navOptions || navItems()}
      navTitle={
        wrapperProps?.navTitle || (
          <AccountLayoutTitle>
            <AuctionStatusBox
              auction={item}
              content={renderStatusBoxContent()}
              customBoxVariant={renderBoxVariant()}
            />
          </AccountLayoutTitle>
        )
      }
      navContent={
        listType === auctionListType.MY_BIDS || listType === auctionListType.SEARCH ? (
          <PlaceBidBox
            proxyBidInPence={proxyBid || pencesToPounds(item?.proxyBidInPence)}
            highestBidInPence={highestBid || pencesToPounds(item?.highestBidInPence) || 0}
            shouldPriceBeGreen={shouldPriceBeGreen}
            item={item}
            refreshItem={refreshItem}
            bids={bidsNotification}
            changeStatus={changeStatus}
            status={status}
          />
        ) : (
          ''
        )
      }
    >
      {render()}
    </AccountLayoutWrapper>
  ) : (
    <>{render()}</>
  );
};

AuctionDetailsItem.defaultProps = {
  nextStep: () => {},
  inWrapper: false,
  setIsRepublish: () => {},
  wrapperProps: null,
  navContentRef: null,
  auctionTimestamp: null,
};

AuctionDetailsItem.propTypes = {
  item: PropTypes.object.isRequired,
  listType: PropTypes.string.isRequired,
  refreshItem: PropTypes.func,
  typeSseClient: PropTypes.string.isRequired,
  nextStep: PropTypes.func,
  setIsRepublish: PropTypes.func,
  navContentRef: PropTypes.object,
  inWrapper: PropTypes.bool,
  wrapperProps: PropTypes.object,
  auctionTimestamp: PropTypes.object,
};
AuctionDetailsItem.defaultProps = {
  refreshItem: () => {},
};

export default withRouter(AuctionDetailsItem);
