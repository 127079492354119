import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import CommonButton from './CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import RouterPaths from '../../constants/RouterPaths';

const cookieKey = 'cookie-policy';

const CookiesContainer = () => {
  const [isOpen, setIsOpen] = useState(
    (() => {
      return !Cookies.get(cookieKey);
    })(),
  );

  return (
    <Modal className="cookies-policy-modal" isOpen={isOpen}>
      <ModalHeader className="cookies-policy-modal__header">Cookie Notice</ModalHeader>
      <ModalBody className="cookies-policy-modal__body">
        <p>
          We use only essential cookies on our website. These cookies are necessary for the
          basic functionality of the site, such as ensuring security, managing your session,
          and providing you with key services. As they are required for the operation of the
          website, no action is needed from you.
        </p>
        <p>
          For more information on how we handle cookies and data, please review our{' '}
          <Link target="_blank" to={RouterPaths.PRIVACY_POLICY}>
            Privacy Policy
          </Link>
          .
        </p>
      </ModalBody>
      <ModalFooter className="cookies-policy-modal__footer pt-2">
        <CommonButton
          label="Got it"
          handleClick={() => {
            Cookies.set(cookieKey, true);
            setIsOpen(false);
          }}
          variant={CommonButtonVariants.PRIMARY}
        />
      </ModalFooter>
    </Modal>
  );
};
export default CookiesContainer;
