import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const AuctionSellingPersonInformation = ({ item }) => {
  const { organizationContact } = item?.seller;

  return (
    <>
      <Row
        className="main-description-container__section-title mt-4"
        id="vehicle-sellers-information"
      >
        <Col md={12} className="p-0 pb-2 text-lg">
          Seller&apos;s Information
        </Col>
      </Row>
      <Row className="mt-3 summary__selling-person-list text-base">
        <p className="m-0 fw-600">{organizationContact?.tradeName || '-'}</p>
      </Row>
      <Row className="summary__selling-person-list text-base">
        <p className="m-0 fw-600">
          {item?.seller.firstName && item?.seller.lastName
            ? `${item?.seller.firstName} ${item?.seller.lastName}`
            : '-'}
        </p>
      </Row>
      <Row className="summary__selling-person-list text-base">
        <p className="m-0 fw-600">
          {organizationContact?.location?.address || '-'}
          {organizationContact?.location?.address && organizationContact?.location?.address2
            ? ` ${organizationContact?.location?.address2}`
            : ''}
        </p>
      </Row>
      <Row className="summary__selling-person-list text-base">
        <p className="m-0 fw-600">{organizationContact?.location?.city || '-'}</p>
      </Row>
      <Row className="summary__selling-person-list text-base">
        <p className="m-0 fw-600">{organizationContact?.location?.county || '-'}</p>
      </Row>
      <Row className="summary__selling-person-list text-base">
        <p className="m-0 fw-600">
          {organizationContact?.location?.postalCode?.postcode || '-'}
        </p>
      </Row>
      <Row className="summary__selling-person-list text-base">
        <p className="m-0 fw-600">{organizationContact?.email || '-'}</p>
      </Row>
      <Row className="summary__selling-person-list text-base">
        <p className="m-0 fw-600">
          {organizationContact?.phoneNumber || '-'}
          {organizationContact?.phoneNumber && organizationContact?.landlinePhoneNumber
            ? ` / ${organizationContact?.landlinePhoneNumber}`
            : ''}
        </p>
      </Row>
    </>
  );
};

AuctionSellingPersonInformation.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AuctionSellingPersonInformation;
