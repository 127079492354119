import React from 'react';
import PropTypes from 'prop-types';
import { AuctionStatusType } from '../constants/AuctionStatusType';

const auctionStatusTypeMapping = {
  [AuctionStatusType.PHYSICAL]: 'PHYSICAL',
  [AuctionStatusType.MINIMUM_PRICE]: 'NO-RESERVE',
  [AuctionStatusType.TRYING_TO_DEAL]: 'TRYING TO DEAL',
};

const auctionStatusTypeNameMapping = {
  [auctionStatusTypeMapping.MINIMUM_PRICE]: 'NO RESERVE'
}

const OrganizationRoleBox = ({ role, listView = true }) => {
  const label = auctionStatusTypeMapping[role] || role;

  return label ? (
    <div
      className={`organization-role-box organization-role-box-${label.toLowerCase()} ${
        listView ? 'organization-role-box-list' : ''
      }`}
    >
      {auctionStatusTypeNameMapping[label] || label}
    </div>
  ) : null;
};

OrganizationRoleBox.propTypes = {
  role: PropTypes.string.isRequired,
};

export default OrganizationRoleBox;
