import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const AuctionBuyerInformation = ({ item }) => {
  const { firstName, lastName, email, phoneNumber, organizationContact } = item?.buyer || {};

  const renderBuyerInformation = (value, name, hideEmpty) => {
    if (hideEmpty && !value) {
      return '';
    }

    return (
      <Row lg={3} md={3} sm={4} xs={12}>
        <p className="m-0 fw-600 text-base">{value || '-'}</p>
      </Row>
    );
  };

  return (
    <>
      <Row
        className="main-description-container__section-title mt-4"
        id="vehicle-buyers-information"
      >
        <Col md={12} className="p-0 pb-2 text-lg">
          Buyer&apos;s Information
        </Col>
      </Row>
      <div className="mt-3">
        {renderBuyerInformation(organizationContact?.tradeName, 'Company Name')}
        {renderBuyerInformation(
          !firstName && !lastName ? '' : `${firstName} ${lastName}`,
          'Contact Name',
        )}
        {renderBuyerInformation(
          `${organizationContact?.location?.address}${
            organizationContact?.location?.address2
              ? ` ${organizationContact?.location?.address2}`
              : ''
          }`,
          'Address - Line 2',
        )}
        {renderBuyerInformation(organizationContact?.location?.city, 'City')}
        {renderBuyerInformation(organizationContact?.location?.county, 'Country')}
        {renderBuyerInformation(
          organizationContact?.location?.postalCode?.postcode,
          'Post Code',
        )}
        {renderBuyerInformation(email, 'Email')}
        {renderBuyerInformation(
          `${phoneNumber}${
            organizationContact?.landlinePhoneNumber
              ? ` / ${organizationContact?.landlinePhoneNumber}`
              : ''
          }`,
          'Mobile',
        )}
      </div>
    </>
  );
};

AuctionBuyerInformation.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AuctionBuyerInformation;
