import _, { get } from 'lodash';
import history from 'browserHistory';
import { reverse } from 'named-urls';
import { DealerStatus } from 'itrade-admin-panel/src/constants/DealerStatus';
import { apiClient } from '../api/ApiClient';
import { handleError, showAlert } from '../common/components/alert/AlertActions';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import { prepareSearchData, SORT_ASC, SORT_DESC } from '../common/helpers/prepareSearchData';
import {
  AUCTION_EVENT_SUCCESS,
  MY_VEHICLES_CLEAR_SUCCESS,
  MY_VEHICLES_SEARCH_SUCCESS,
  SET_VEHICLES_FILTER,
} from './MyVehiclesReducer';
import AlertVariant from '../common/components/alert/AlertVariant';
import {
  CANCEL_AUCTION_SUCCESS,
  CLEAR_CONFIRM_LAUNCH_VALIDATION_ERRORS_SUCCESS,
  CLEAR_RESPONSE_DATA_SUCCESS,
  CONFIRM_LAUNCH_FAILURE,
  DELETE_AUCTION_SUCCESS,
  PUBLISH_AUCTION_SUCCESS,
  REJECTION_AUCTION_SUCCESS,
  REPUBLISH_AUCTION_SUCCESS,
  SET_IS_RELAUNCH_ALLOWED_SUCCESS,
} from '../addvehicle/AddVehicleReducer';
import RouterPaths from '../constants/RouterPaths';
import { parseCurrencyToIntIfString } from '../common/helpers/parseCurrencyToIntIfString';
import { AuctionStatusType } from '../constants/AuctionStatusType';
import { DueInDateType } from '../constants/DueInDateType';
import { reverseDateToString } from '../common/utils/date/Date.utils';

export const AUCTION_API_ENDPOINT = '/auction';

export const searchMyAuctions = (values, callback) => (dispatch, getState) => {
  dispatch(showLoader());
  const prevVehicle = values?.offset > 0 ? getState().myVehicles?.toJS()?.items || [] : [];
  const prevVehicleIds = prevVehicle.map(item => item?.id);

  const sortOption = { orderByEndsOn: SORT_ASC, lastModifiedOn: SORT_DESC };

  const preparedValues = {
    ...values,
  };

  return apiClient
    .post('/auction/my-auctions/search', prepareSearchData(preparedValues, sortOption))
    .then(res => {
      if (res.status === 200) {
        const items = get(res, 'data.data.content', []);
        items.map(item => {
          item.timeStamp = new Date();
          return item;
        });
        dispatch({
          type: MY_VEHICLES_SEARCH_SUCCESS,
          payload: {
            items: _.uniqBy(
              [...prevVehicle, ...get(res, 'data.data.content', [])],
              item => item.id,
            ),
            totalItems: get(res, 'data.data.totalElements', 0),
            hasMore: !get(res, 'data.data.last', true),
            offset: values?.offset,
            timeStamp: new Date(),
          },
        });

        const auctionsIds = get(res, 'data.data.content', []).map(item => item.id);
        const ids = prevVehicleIds || [];
        callback(_.uniq([...ids, ...auctionsIds]));
      }
    })
    .catch(err => {
      if (err?.response?.status === 403) {
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const clearMyAuctions = () => dispatch => {
  dispatch({
    type: MY_VEHICLES_CLEAR_SUCCESS,
  });
};

export const getAuction = (id, callback, noLoader = false) => dispatch => {
  if (!noLoader) {
    dispatch(showLoader());
  }

  return apiClient
    .get(`/auction/${id}`)
    .then(res => {
      if (res.status === 200) {
        if (typeof callback === 'function') {
          setTimeout(() => {
            const data = get(res, 'data.data');
            data.timeStamp = new Date();
            callback(data);
          }, 1000);
        }
      }
    })
    .catch(err => {
      if (err?.response?.status === 302) {
        return history.push(
          reverse(RouterPaths.MY_VEHICLES_DETAILS, { id: err?.response?.data?.data?.id }),
        );
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const publishAuction = (
  {
    id,
    type,
    minimumPriceInPence,
    autoAcceptPriceInPence,
    tryingToDealDeliveryDate,
    tryingToDealDeliveryDateType,
    timeOption,
    publishTarget,
    version,
  },
  callback,
  shouldHideLoader = true // Still loader will be hided on error.
) => (dispatch, getState) => {
  dispatch(showLoader());

  const data = {
    id,
    type: type?.value || type,
    minimumPriceInPence: parseCurrencyToIntIfString(minimumPriceInPence) * 100 || null,
    autoAcceptPriceInPence: parseCurrencyToIntIfString(autoAcceptPriceInPence) * 100 || null,
    tryingToDealDeliveryDate:
      type?.value === AuctionStatusType.TRYING_TO_DEAL &&
      tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
        ? reverseDateToString(tryingToDealDeliveryDate)
        : null,
    tryingToDealDeliveryDateType:
      type?.value === AuctionStatusType.TRYING_TO_DEAL
        ? tryingToDealDeliveryDateType?.value
        : null,
    timeOption: timeOption?.value || timeOption,
    publishTarget,
    version,
  };

  const userStatus = getState()?.auth?.toJS()?.status;

  return apiClient
    .post(`/auction/${id}/publish`, data, {
      timeout: 10000,
    })
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: PUBLISH_AUCTION_SUCCESS, payload: res?.data?.data });
        if (typeof callback === 'function') {
          callback(res?.data?.data);
        }
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        return dispatch({
          type: CONFIRM_LAUNCH_FAILURE,
          payload: err?.response?.data?.data,
        });
      }
      if (
        err?.response?.status === 418 &&
        userStatus === DealerStatus.BLOCKED_DUE_TO_PAYMENT
      ) {
        dispatch(hideLoader());
        return dispatch(handleError('Please enter a valid credit card number.'));
      }
      return dispatch(handleError(err));
    })
    .finally(() => {
      if (shouldHideLoader) {
        return dispatch(hideLoader());
      }

      return true;
    });
};

export const cancelAuction = ({ id, version }, callback) => dispatch => {
  dispatch(showLoader());

  return apiClient
    .post(`/auction/${id}/cancel`, { id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: CANCEL_AUCTION_SUCCESS, payload: res?.data?.data });
        if (typeof callback === 'function') {
          callback(res?.data?.data);
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const setRelaunchAllow = isRelaunchAllow => dispatch => {
  dispatch({ type: SET_IS_RELAUNCH_ALLOWED_SUCCESS, payload: isRelaunchAllow });
};

export const deleteAuction = ({ id, version }) => dispatch => {
  dispatch(showLoader());

  return apiClient
    .post(`${AUCTION_API_ENDPOINT}/${id}/delete`, { id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: DELETE_AUCTION_SUCCESS });
        history.push(RouterPaths.MY_VEHICLES);
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const clearResponseData = () => dispatch => {
  dispatch({ type: CLEAR_RESPONSE_DATA_SUCCESS });
};

export const handleAuctionEvent = (event, getAuctionCallback) => dispatch => {
  dispatch({
    type: AUCTION_EVENT_SUCCESS,
    payload: event,
  });
  if (typeof getAuctionCallback === 'function') {
    getAuctionCallback();
  }
};

export const acceptByBuyer = ({ id, version }, callback) => dispatch => {
  dispatch(showLoader());

  return apiClient
    .post(`/auction/${id}/acceptance/buyer`, { id, version })
    .then(res => {
      if (res.status === 200) {
        if (typeof callback === 'function') {
          callback(res?.data?.data);
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const acceptBySeller = ({ id, version }, callback) => dispatch => {
  dispatch(showLoader());

  return apiClient
    .post(`/auction/${id}/acceptance/seller`, { id, version })
    .then(res => {
      if (res.status === 200) {
        if (typeof callback === 'function') {
          callback(res?.data?.data);
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const republishAuction = (
  {
    id,
    type,
    minimumPriceInPence,
    autoAcceptPriceInPence,
    tryingToDealDeliveryDate,
    tryingToDealDeliveryDateType,
    timeOption,
    publishTarget,
    version,
  },
  callback,
  shouldHideLoader = true // Still loader will be hided on error.
) => dispatch => {
  dispatch(showLoader());

  const data = {
    id,
    type: type?.value || type,
    minimumPriceInPence: parseCurrencyToIntIfString(minimumPriceInPence) * 100 || null,
    autoAcceptPriceInPence: parseCurrencyToIntIfString(autoAcceptPriceInPence) * 100 || null,
    tryingToDealDeliveryDate:
      type?.value === AuctionStatusType.TRYING_TO_DEAL &&
      tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
        ? reverseDateToString(tryingToDealDeliveryDate)
        : null,
    tryingToDealDeliveryDateType:
      type?.value === AuctionStatusType.TRYING_TO_DEAL
        ? tryingToDealDeliveryDateType?.value
        : null,
    timeOption: timeOption?.value || timeOption,
    publishTarget,
    version,
  };

  return apiClient
    .post(`/auction/${id}/republish`, data, {
      timeout: 10000,
    })
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: REPUBLISH_AUCTION_SUCCESS, payload: res?.data?.data });
        if (typeof callback === 'function') {
          callback(res?.data?.data);
        }
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        return dispatch({
          type: CONFIRM_LAUNCH_FAILURE,
          payload: err?.response?.data?.data,
        });
      }

      dispatch(hideLoader());
      return dispatch(handleError(err));
    })
    .finally(() => {
      if (shouldHideLoader) {
        return dispatch(hideLoader());
      }

      return true;
    });
};

export const rejectBySeller = ({ id, version }, callback) => dispatch => {
  dispatch(showLoader());

  return apiClient
    .post(`/auction/${id}/rejection/seller`, { id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: REJECTION_AUCTION_SUCCESS, payload: res?.data?.data });
        if (typeof callback === 'function') {
          callback(res?.data?.data);
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const rejectByBuyer = ({ id, version }, callback) => dispatch => {
  dispatch(showLoader());

  return apiClient
    .post(`/auction/${id}/rejection/buyer`, { id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: REJECTION_AUCTION_SUCCESS, payload: res?.data?.data });
        if (typeof callback === 'function') {
          callback(res?.data?.data);
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const setVehicleFilter = status => dispatch => {
  dispatch({
    type: SET_VEHICLES_FILTER,
    payload: status,
  });
};

export const refreshAuctionList = ({ auctionId, context }, successCallback) => dispatch =>
  apiClient
    .post(`${AUCTION_API_ENDPOINT}/${auctionId}/availability`, { context })
    .then(res => {
      if (res.status === 200) {
        if (res?.data?.data?.available && typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => {
      if (err?.response?.status === 418) return null;
      return dispatch(handleError(err));
    });

export const clearConfirmLaunchErrors = () => dispatch => {
  dispatch({ type: CLEAR_CONFIRM_LAUNCH_VALIDATION_ERRORS_SUCCESS });
};
