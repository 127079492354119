import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AuctionStatusTypeName, AvailableAuctionStatuses } from 'constants/AuctionStatusType';
import { useHistory } from 'react-router-dom';
import { AuctionTime, AuctionTimeName } from '../constants/AuctionTime';
import { prepareSelectLabel } from '../common/helpers/prepareSelectLabel';
import { prepareTryingToDealDeliveryTypeLabel } from '../common/helpers/prepareTryingToDealDeliveryTypeLabel';
import ConfirmLaunchForm from './forms/ConfirmLaunchForm';
import { AuctionPublishType } from '../constants/AuctionPublishType';
import {
  clearConfirmLaunchErrors,
  publishAuction,
  republishAuction,
  setVehicleFilter,
} from '../myvehicles/MyVehiclesActions';
import validationSchema from './forms/ConfirmLaunchForm.schema';
import {
  AccountLayoutNavItem,
  AccountLayoutWrapper,
} from '../common/components/AccountLayoutWrapper';
import RouterPaths from '../constants/RouterPaths';
import { AUCTION_PUBLISHED, SSE_SELLER, SSEClient } from '../api/SSEClient';
import { hideLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import { AuctionStatus } from '../constants/AuctionStatus';

const sseClientSeller = new SSEClient(SSE_SELLER);

const AddVehicleConfirmPage = ({ item, prevStep, isRepublish, refreshItem }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!isSeller) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isBuyer, isSeller, history]);

  useEffect(() => {
    refreshItem();
    window.scrollTo(0, 0);
    return () => dispatch(clearConfirmLaunchErrors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const prepareTimeOptionSelect = () => {
    const {
      AUCTION_TIME_30,
      AUCTION_TIME_45,
      AUCTION_TIME_60,
      AUCTION_TIME_180,
      AUCTION_TIME_1440,
      AUCTION_TIME_2880,
      AUCTION_TIME_4320,
      AUCTION_TIME_5760,
      AUCTION_TIME_7200,
      AUCTION_TIME_8640,
      AUCTION_TIME_10080,
      AUCTION_TIME_11520,
      AUCTION_TIME_12960,
      AUCTION_TIME_14400,
      AUCTION_TIME_15840,
      AUCTION_TIME_17280,
      AUCTION_TIME_18720,
      AUCTION_TIME_20160,
    } = AuctionTime;

    const {
      AUCTION_TIME_30: AUCTION_TIME_30_LABEL,
      AUCTION_TIME_45: AUCTION_TIME_45_LABEL,
      AUCTION_TIME_60: AUCTION_TIME_60_LABEL,
      AUCTION_TIME_180: AUCTION_TIME_180_LABEL,
      AUCTION_TIME_1440: AUCTION_TIME_1440_LABEL,
      AUCTION_TIME_2880: AUCTION_TIME_2880_LABEL,
      AUCTION_TIME_4320: AUCTION_TIME_4320_LABEL,
      AUCTION_TIME_5760: AUCTION_TIME_5760_LABEL,
      AUCTION_TIME_7200: AUCTION_TIME_7200_LABEL,
      AUCTION_TIME_8640: AUCTION_TIME_8640_LABEL,
      AUCTION_TIME_10080: AUCTION_TIME_10080_LABEL,
      AUCTION_TIME_11520: AUCTION_TIME_11520_LABEL,
      AUCTION_TIME_12960: AUCTION_TIME_12960_LABEL,
      AUCTION_TIME_14400: AUCTION_TIME_14400_LABEL,
      AUCTION_TIME_15840: AUCTION_TIME_15840_LABEL,
      AUCTION_TIME_17280: AUCTION_TIME_17280_LABEL,
      AUCTION_TIME_18720: AUCTION_TIME_18720_LABEL,
      AUCTION_TIME_20160: AUCTION_TIME_20160_LABEL,
    } = AuctionTimeName;

    switch (item?.timeOption) {
      case AUCTION_TIME_30:
        return { label: AUCTION_TIME_30_LABEL, value: AUCTION_TIME_30 };
      case AUCTION_TIME_45:
        return { label: AUCTION_TIME_45_LABEL, value: AUCTION_TIME_45 };
      case AUCTION_TIME_60:
        return { label: AUCTION_TIME_60_LABEL, value: AUCTION_TIME_60 };
      case AUCTION_TIME_180:
        return { label: AUCTION_TIME_180_LABEL, value: AUCTION_TIME_180 };
      case AUCTION_TIME_1440:
        return { label: AUCTION_TIME_1440_LABEL, value: AUCTION_TIME_1440 };
      case AUCTION_TIME_2880:
        return { label: AUCTION_TIME_2880_LABEL, value: AUCTION_TIME_2880 };
      case AUCTION_TIME_4320:
        return { label: AUCTION_TIME_4320_LABEL, value: AUCTION_TIME_4320 };
      case AUCTION_TIME_5760:
        return { label: AUCTION_TIME_5760_LABEL, value: AUCTION_TIME_5760 };
      case AUCTION_TIME_7200:
        return { label: AUCTION_TIME_7200_LABEL, value: AUCTION_TIME_7200 };
      case AUCTION_TIME_8640:
        return { label: AUCTION_TIME_8640_LABEL, value: AUCTION_TIME_8640 };
      case AUCTION_TIME_10080:
        return { label: AUCTION_TIME_10080_LABEL, value: AUCTION_TIME_10080 };
      case AUCTION_TIME_11520:
        return { label: AUCTION_TIME_11520_LABEL, value: AUCTION_TIME_11520 };
      case AUCTION_TIME_12960:
        return { label: AUCTION_TIME_12960_LABEL, value: AUCTION_TIME_12960 };
      case AUCTION_TIME_14400:
        return { label: AUCTION_TIME_14400_LABEL, value: AUCTION_TIME_14400 };
      case AUCTION_TIME_15840:
        return { label: AUCTION_TIME_15840_LABEL, value: AUCTION_TIME_15840 };
      case AUCTION_TIME_17280:
        return { label: AUCTION_TIME_17280_LABEL, value: AUCTION_TIME_17280 };
      case AUCTION_TIME_18720:
        return { label: AUCTION_TIME_18720_LABEL, value: AUCTION_TIME_18720 };
      case AUCTION_TIME_20160:
        return { label: AUCTION_TIME_20160_LABEL, value: AUCTION_TIME_20160 };
      default:
        return null;
    }
  };

  useEffect(() => {
    if (item?.id) {
      sseClientSeller.listen([item?.id], response => {
        if (response?.type === AUCTION_PUBLISHED) {
          dispatch(setVehicleFilter(AuctionStatus.PUBLISHED));
          history.push(`${RouterPaths.MY_VEHICLES}#vehicleFilter`);
        }
      });
    }

    return () => {
      dispatch(hideLoader());
      sseClientSeller.close();
    };
  }, [item.id]);

  const prepareAutoAcceptValue = () => {
    return item?.autoAcceptPriceInPence ? item?.autoAcceptPriceInPence / 100 : '';
  };

  const initialValues = {
    id: item?.id || '',
    type: (() => {
      if (item?.type && AvailableAuctionStatuses.includes(item?.type)) {
        return {
          label: AuctionStatusTypeName?.[item?.type]
            ? AuctionStatusTypeName?.[item?.type]
            : prepareSelectLabel(item?.type),
          value: item?.type,
        };
      }

      return {
        label: '',
        value: '',
      };
    })(),
    autoAcceptPriceInPence: prepareAutoAcceptValue(),
    minimumPriceInPence: item?.minimumPriceInPence ? item?.minimumPriceInPence / 100 : '',
    timeOption: item?.timeOption ? prepareTimeOptionSelect() : null,
    tryingToDealDeliveryDate: item?.tryingToDealDeliveredOn || new Date(),
    publishTarget: item?.publishTarget ? item?.publishTarget : AuctionPublishType.EVERYWHERE,
    tryingToDealDeliveryDateType: item?.tryingToDealDeliveredDateType
      ? {
          label: prepareTryingToDealDeliveryTypeLabel(item?.tryingToDealDeliveredDateType),
          value: item?.tryingToDealDeliveredDateType,
        }
      : null,
    version: typeof item?.version === 'number' ? item?.version : null,
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (isRepublish) {
      dispatch(
        republishAuction(
          values,
          () => {
            // Check if sse connection could not be established.
            if (sseClientSeller?.client?.readyState !== 1) {
              setTimeout(() => {
                dispatch(setVehicleFilter(AuctionStatus.PUBLISHED));
                history.push(`${RouterPaths.MY_VEHICLES}#vehicleFilter`);
              }, 1000);
            }
            // refreshItem(data);
            // prevStep();
          },
          false,
        ),
      );
    } else {
      dispatch(
        publishAuction(
          values,
          () => {
            // Check if sse connection could not be established.
            if (sseClientSeller?.client?.readyState !== 1) {
              setTimeout(() => {
                dispatch(setVehicleFilter(AuctionStatus.PUBLISHED));
                history.push(`${RouterPaths.MY_VEHICLES}#vehicleFilter`);
              }, 1000);
            }
            // refreshItem(data);
            // prevStep();
          },
          false,
        ),
      );
    }
    setSubmitting(false);
  };

  const navItems = useCallback(() => {
    return [
      <AccountLayoutNavItem link={RouterPaths.MY_VEHICLES} hide={!hasAccess}>
        SELLING
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem link={RouterPaths.MY_BIDS} hide={!isBuyer}>
        BUYING
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem link={RouterPaths.ANALYTICS}>ANALYTICS</AccountLayoutNavItem>,
    ];
  }, [hasAccess, isBuyer]);

  return (
    <AccountLayoutWrapper navOptions={navItems()}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {form => <ConfirmLaunchForm form={form} prevStep={prevStep} item={item} />}
      </Formik>
    </AccountLayoutWrapper>
  );
};

AddVehicleConfirmPage.defaultProps = {
  item: null,
};

AddVehicleConfirmPage.propTypes = {
  item: PropTypes.object,
  prevStep: PropTypes.func.isRequired,
  isRepublish: PropTypes.bool.isRequired,
};

export default AddVehicleConfirmPage;
