import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import AddVehicleForm from './forms/AddVehicleForm';
import RouterPaths from '../constants/RouterPaths';
import { clearAuction, disableAuctionEditing } from './AddVehicleActions';
import { capitalizeString } from '../common/helpers/capitalizeString';
import {
  Transmission as VehicleTransmissions,
  Fuel as VehicleFuels,
  Doors as VehicleDoors,
} from '../constants/Vehicle';

const AddVehiclePage = ({ history }) => {
  const dispatch = useDispatch();
  const autofill = useSelector(state => state.addVehicle.get('autofill'));
  const basicDataSaved = useSelector(state => state.addVehicle.get('basicDataSaved'));
  const isAuctionEditing = useSelector(state => state.addVehicle.get('isAuctionEditing'));
  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const basicData = useSelector(state => state.addVehicle.get('basicData'));
  const vehicleFeaturesDictionaryIds = useSelector(state =>
    state.addVehicle.get('vehicleFeatures'),
  )?.vehicleFeaturesDictionaryIds;
  const vehicleFeaturesCustom = useSelector(state => state.addVehicle.get('vehicleFeatures'))
    ?.vehicleFeaturesCustom;
  const allVehicleFeatures = useSelector(state => state.addVehicle.get('allVehicleFeatures'));
  const firstRender = useSelector(state => state.myWallet.get('firstRender'));
  const vehicleMedia = useSelector(state => state.addVehicle.get('vehicleMedia'));

  const [hasAccess, setHasAccess] = useState(false);

  const preparedCapFeaturesArr =
    allVehicleFeatures &&
    allVehicleFeatures.map(item => {
      if (vehicleFeaturesDictionaryIds && vehicleFeaturesDictionaryIds.includes(item?.id)) {
        return item.value;
      }
      return null;
    });

  useEffect(() => {
    if (isBuyer && !isSeller) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isBuyer, isSeller, history]);

  useEffect(() => {
    if (!isAuctionEditing) dispatch(clearAuction());
    return () => dispatch(disableAuctionEditing());
  }, [dispatch, isAuctionEditing]);

  const hasEquivalent = (value, list) => {
    if (Array.isArray(list)) {
      return list.includes(value);
    }
    return Object.values(list).includes(value);
  };

  const initialValues = {
    basicData: {
      registrationPlate: basicData?.registrationPlate || '',
      makeSelect:
        autofill || basicDataSaved
          ? { value: basicData?.makeId, label: basicData?.makeName }
          : '',
      modelSelect:
        autofill || basicDataSaved
          ? { value: basicData?.modelId, label: basicData?.modelName }
          : '',
      mileage: autofill || basicDataSaved ? basicData?.mileage : '',
      year:
        autofill || basicDataSaved ? { value: basicData?.year, label: basicData?.year } : '',
      mot: autofill || basicDataSaved ? basicData?.mot : '',
      derivative: autofill || basicDataSaved ? basicData?.derivative : '',
      carType: autofill || basicDataSaved ? capitalizeString(basicData?.carType) : '',
      transmission:
        (autofill || basicDataSaved) &&
        hasEquivalent(basicData?.transmission, VehicleTransmissions)
          ? { value: basicData?.transmission, label: basicData?.transmission }
          : '',
      engineSize: (autofill || basicDataSaved) && basicData?.engineSize ? basicData?.engineSize : '',
      fuel:
        (autofill || basicDataSaved) && hasEquivalent(basicData?.fuel, VehicleFuels)
          ? { value: basicData?.fuel, label: basicData?.fuel }
          : '',
      colour: autofill || basicDataSaved ? capitalizeString(basicData?.colour) : '',
      doors:
        (autofill || basicDataSaved) && hasEquivalent(basicData?.doors, VehicleDoors)
          ? { value: basicData?.doors, label: basicData?.doors }
          : '',
      dateOfFirstRegistration:
        autofill || basicDataSaved ? basicData?.dateOfFirstRegistration : '',
      previousKeepersInTotal:
        autofill || (basicDataSaved && basicData?.previousKeepersInTotal !== null)
          ? basicData?.previousKeepersInTotal
          : '',
      vehicleCapCapId: basicData?.capId || basicData?.vehicleCapCapId,
      // vin: (autofill && capitalizeString(basicData?.vin)) || '',
      vin: autofill || basicData?.vin ? capitalizeString(basicData?.vin) : '',
    },
    tyreThreadDepths: {
      nearsideFront: '',
      nearsideRear: '',
      offsideFront: '',
      offsideRear: '',
    },
    vehicleMedia: (!firstRender && vehicleMedia) || {
      photos: [],
      photosToDelete: [],
      videos: [],
      videosToDelete: [],
    },
    serviceHistory: {
      type: '',
      manualRecords: [{ date: '', mileage: '', serviceName: '' }],
      photoRecords: [],
      photoRecordsToDelete: [],
    },
    vehicleFeatures: {
      vehicleFeaturesDictionary: vehicleFeaturesDictionaryIds?.length
        ? vehicleFeaturesDictionaryIds
        : [],
      vehicleFeaturesCustom: vehicleFeaturesCustom?.length ? vehicleFeaturesCustom : [],
    },
    descriptionAndSettings: {
      description: '',
      locationId: '',
      type: '',
      minimumPriceInPence: 0,
      tryingToDealDeliveryDate: '',
      timeOption: '',
      publishTarget: '',
    },
    capFeatures: vehicleFeaturesDictionaryIds ? preparedCapFeaturesArr : [],
    customFeatures: [],
    newCustomFeature: '',
  };

  return (
    <>
      {hasAccess && (
        <Formik
          initialValues={initialValues}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={() => null}
        >
          {form => (
            <>
              <AddVehicleForm form={form} values={form?.values} touched={form?.touched} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddVehiclePage;
