import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import history from 'browserHistory.js';
import RouterPaths from '../../../constants/RouterPaths';
import HomePage from '../../../homepage/HomePage';
import AboutUs from '../../../aboutus/AboutUs';
import Login from '../../../auth/Login';
import ForgotPasswordPage from '../../../auth/forgotpassword/ForgotPasswordPage';
import ConfirmForgotPasswordPage from '../../../auth/forgotpassword/ConfirmForgotPasswordPage';
import ResetPasswordPage from '../../../auth/resetpassword/ResetPasswordPage';
import RegisterPage from '../../../register/RegisterPage';
import ProtectedRoute from '../../hoc/ProtectedRoute';
import SettingsPage from '../../../settings/SettingsPage';
import EditEmployeePage from '../../../settings/employees/edit/EditEmployeePage';
import CreateEmployeePage from '../../../settings/employees/create/CreateEmployeePage';
import AddVehiclePage from '../../../addvehicle/AddVehiclePage';
import EmployeesPage from '../../../settings/employees/EmployeesPage';
import MyProfilePage from '../../../settings/myprofile/MyProfilePage';
import SearchAlertsPage from '../../../settings/searchalerts/SearchAlertsPage';
import NotificationsPage from '../../../settings/notifications/NotificationsPage';
import MyWalletPage from '../../../mywallet/MyWalletPage';
import SearchPage from '../../../search/SearchPage';
import SearchDetailsPage from '../../../search/details/SearchDetailsPage';
import TransactionsPage from '../../../settings/transactions/TransactionsPage';
import InvoicesPage from '../../../settings/invoices/InvoicesPage';
import CustomSite from '../../../customsite/CustomSite';
import MyVehiclesPage from '../../../myvehicles/MyVehiclesPage';
import MyVehiclesDetailsPage from '../../../myvehicles/details/MyVehiclesDetailsPage';
import MyBidsPage from '../../../mybids/MyBidsPage';
import MyBidsDetailsPage from '../../../mybids/details/MyBidsDetailsPage';
import { getTokenInfo } from '../../../settings/myprofile/MyProfileActions';
import { getPaymentMethod } from '../../../mywallet/MyWalletActions';
import { UserStatus } from '../../../constants/UserStatus';
import ImpersonatePage from '../../../auth/impersonate/ImpersonatePage';
import CreateSearchAlertPage from '../../../settings/searchalerts/create/CreateSearchAlertPage';
import EditSearchAlertsPage from '../../../settings/searchalerts/edit/EditSearchAlertPage';
import Header from '../Header/Header';
import Footer from '../../../footer/Footer';
import AnalyticsPage from '../../../analytics/AnalyticsPage';
import CookiesContainer from '../CookiesContainer';

const Content = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const isSearchPage = location.pathname === RouterPaths.SEARCH;
  const isImpersonatePage = location.pathname === RouterPaths.IMPERSONATE;
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const userAuthStatus = useSelector(state => state.auth.get('status'));
  const isOwner = useSelector(state => state.auth.get('isOwner'));

  const excludeHeaderPaths = [
    RouterPaths.REGISTER,
    RouterPaths.LOGIN,
    RouterPaths.FORGOT_PASSWORD,
    RouterPaths.CONFIRM_FORGOT_PASSWORD,
    '/reset-password',
  ];

  const includeFooterPaths = [
    RouterPaths.HOMEPAGE,
    RouterPaths.TERMS_AND_CONDITIONS,
    RouterPaths.FAQ,
    RouterPaths.PRIVACY_POLICY,
    RouterPaths.ABOUT_US,
  ];

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getTokenInfo());
      if (isOwner && !isImpersonatePage) {
        dispatch(getPaymentMethod());
      }
      if (userAuthStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT)
        history.push(RouterPaths.MY_WALLET);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuthenticated, userAuthStatus, isOwner]);

  const canShowHeader = () => {
    const parts = location.pathname.split('/');
    return !excludeHeaderPaths.includes(`/${parts[1]}`);
  };

  // eslint-disable-next-line no-unused-vars
  const canShowFooter = () => {
    const parts = location.pathname.split('/');
    const routes = Object.values(RouterPaths)
      .map(el => el.split('/')[1] || null)
      .filter(el => el);

    return (
      includeFooterPaths.includes(location.pathname) ||
      (!routes.includes(`${parts[1]}`) && !routes.includes(location.pathname))
    );
  };

  return (
    <>
      {canShowHeader() ? <Header /> : ''}
      <div className="page-content">
        {isSearchPage ? null : window.scrollTo(0, 0)}
        <Switch>
          <Route path={RouterPaths.HOMEPAGE} component={HomePage} exact />
          <Route path={RouterPaths.LOGIN} component={Login} exact />
          <Route path={RouterPaths.FORGOT_PASSWORD} component={ForgotPasswordPage} exact />
          <Route
            path={RouterPaths.CONFIRM_FORGOT_PASSWORD}
            component={ConfirmForgotPasswordPage}
            exact
          />
          <Route path={RouterPaths.RESET_PASSWORD} component={ResetPasswordPage} exact />
          <Route path={RouterPaths.REGISTER} component={RegisterPage} exact />
          <Route path={RouterPaths.IMPERSONATE} component={ImpersonatePage} exact />
          <Route path={RouterPaths.ABOUT_US} component={AboutUs} exact />
          <ProtectedRoute path={RouterPaths.ADD_VEHICLE} component={AddVehiclePage} exact />
          <ProtectedRoute path={RouterPaths.SETTINGS} component={SettingsPage} exact />
          <ProtectedRoute path={RouterPaths.DASHBOARD} component={MyVehiclesPage} exact />
          <ProtectedRoute path={RouterPaths.EMPLOYEES} component={EmployeesPage} exact />
          <ProtectedRoute
            path={RouterPaths.EMPLOYEE_DETAILS}
            component={EditEmployeePage}
            exact
          />
          <ProtectedRoute
            path={RouterPaths.CREATE_EMPLOYEE}
            component={CreateEmployeePage}
            exact
          />
          <ProtectedRoute path={RouterPaths.MY_PROFILE} component={MyProfilePage} exact />
          <ProtectedRoute
            path={RouterPaths.SEARCH_ALERTS}
            component={SearchAlertsPage}
            exact
          />
          <ProtectedRoute
            path={RouterPaths.CREATE_SEARCH_ALERT}
            component={CreateSearchAlertPage}
            exact
          />
          <ProtectedRoute
            path={RouterPaths.EDIT_SEARCH_ALERT}
            component={EditSearchAlertsPage}
            exact
          />
          <ProtectedRoute
            path={RouterPaths.NOTIFICATIONS}
            component={NotificationsPage}
            exact
          />
          <ProtectedRoute path={RouterPaths.MY_VEHICLES} component={MyVehiclesPage} exact />
          <ProtectedRoute path={RouterPaths.ANALYTICS} component={AnalyticsPage} exact />
          <ProtectedRoute
            path={RouterPaths.MY_VEHICLES_DETAILS}
            component={MyVehiclesDetailsPage}
            exact
          />
          <ProtectedRoute path={RouterPaths.MY_WALLET} component={MyWalletPage} exact />
          <ProtectedRoute path={RouterPaths.SEARCH} component={SearchPage} exact />
          <ProtectedRoute
            path={RouterPaths.SEARCH_DETAILS}
            component={SearchDetailsPage}
            exact
          />
          <ProtectedRoute path={RouterPaths.TRANSACTIONS} component={TransactionsPage} exact />
          <ProtectedRoute path={RouterPaths.INVOICES} component={InvoicesPage} exact />
          <ProtectedRoute path={RouterPaths.MY_BIDS} component={MyBidsPage} exact />
          <ProtectedRoute
            path={RouterPaths.MY_BIDS_DETAILS}
            component={MyBidsDetailsPage}
            exact
          />
          <Route component={CustomSite} exact />
        </Switch>
      </div>
      <Footer />
      <CookiesContainer />
    </>
  );
};

export default Content;
