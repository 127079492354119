import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { reverse } from 'named-urls';
import { useSelector } from 'react-redux';
import { preparePriceFormat } from 'common/helpers/preparePriceFormat';
import { sortedByPerspective } from 'addvehicle/helpers/sortByPerspective';
import noImageImg from '../assets/img/search/noimage.svg';
import RouterPaths from '../constants/RouterPaths';
import { capitalizeString } from '../common/helpers/capitalizeString';
import {
  AuctionBarColors,
  AuctionStatus,
  AuctionStatusColors,
} from '../constants/AuctionStatus';
import { AuctionItemMessage } from '../constants/AuctionItemMessage';
import OrganizationRoleBox from '../myvehicles/OrganizationRoleBox';
import { pencesToPounds } from '../common/helpers/pencesToPounds';
import AuctionTypeLabel from '../myvehicles/AuctionTypeLabel';
import { getPriceColor } from '../common/helpers/auctionColorHelper';
import { auctionListType } from '../constants/AuctionListType';
import CurrentBidLabel from '../myvehicles/CurrentBidLabel';
import {
  AUCTION_BID_MAX_BIDS_LIMIT,
  AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE,
} from '../constants/AuctionBid';
import { checkIsPropertySpecified } from '../common/helpers/checkIsPropertySpecified';
import carCalendarBlue from '../assets/img/vehicles/car-calendar-blue.svg';
import carRegistrationBlue from '../assets/img/vehicles/car-registration-blue.svg';
import carMileageBlue from '../assets/img/vehicles/car-mileage-blue.svg';
import carTransmissionBlue from '../assets/img/vehicles/car-transmission-blue.svg';
import carEngineBlue from '../assets/img/vehicles/car-engine-blue.svg';
import carFuelBlue from '../assets/img/vehicles/car-fuel-blue.svg';
import carColorBlue from '../assets/img/vehicles/car-color-blue.svg';
import carLocationBlue from '../assets/img/vehicles/car-location-blue.svg';
import { AuctionStatusTypeName } from '../constants/AuctionStatusType';
import AuctionMainDescriptionContainer, {
  SlotItems,
} from '../common/components/Auction/AuctionMainDescriptionContainer';
import Derivative from '../common/components/Auction/Derivative';
import { prepareVehicleTitle } from '../common/helpers/prepareVehicleTitle';

const AuctionItem = ({ item, history, isLatestVehiclesSection, locationState, getRef }) => {
  const bidErrorMessage = useSelector(state => state.search.get('bidErrorMessage'));
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const [bidBarColor, setBidBarColor] = useState('');
  const [bidBarMessage, setBidBarMessage] = useState('');
  const [windowFocus, setWindowFocus] = useState(false);
  // const organizationRole = item?.organizationRole;
  const highestBidInPence = item?.highestBidInPence;

  const windowOnFocus = () => {
    setWindowFocus(true);
  };

  const windowOnBlur = () => {
    setWindowFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', windowOnFocus);
    window.addEventListener('blur', windowOnBlur);
    return () => {
      window.removeEventListener('focus', windowOnFocus);
      window.removeEventListener('blur', windowOnBlur);
    };
  }, []);

  useEffect(() => {
    if (
      (item?.seller?.organizationContact?.id === organizationId ||
        item?.organizationId === organizationId) &&
      !isLatestVehiclesSection
    ) {
      // renderSellerItemMessage();
    } else if (item?.bids.some(item => item?.bidderOrganizationId === organizationId)) {
      renderBuyerItemMessage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  let image = noImageImg;
  if (item?.vehicle?.vehicleMedia?.photos?.length) {
    const photos = sortedByPerspective(item?.vehicle?.vehicleMedia.photos);
    image = photos[0]?.fileUrl;
  }

  const title = prepareVehicleTitle(item?.vehicle);

  let highestBid = null;
  if (item && item.bids?.length) {
    highestBid = item.bids.filter(bid => bid.isHighest)?.[0];
  }

  const isHighestBid = highestBid?.bidderOrganizationId === organizationId;

  const organizationHighestBids = item?.bids
    ?.filter(item => item.bidderOrganizationId === organizationId)
    .map(item => item.amountInPence);

  const organizationHighestBid =
    organizationHighestBids.length > 0 &&
    organizationHighestBids.reduce((a, b) => {
      return Math.max(a, b);
    });

  // eslint-disable-next-line no-unused-vars
  const renderSellerItemMessage = () => {
    let message;
    let statusColor;
    switch (item?.status) {
      case AuctionStatus.PUBLISHED:
        message = AuctionItemMessage.PUBLISHED;
        statusColor = AuctionStatusColors.PUBLISHED;
        break;
      case AuctionStatus.CANCELLED:
        message = AuctionItemMessage.CANCELLED;
        statusColor = AuctionStatusColors.CANCELLED;
        break;
      case AuctionStatus.TO_ACCEPT:
        message = AuctionItemMessage.TO_ACCEPT_SELLER;
        statusColor = AuctionStatusColors.TO_ACCEPT_BUYER;
        break;
      case AuctionStatus.WAITING:
        message = AuctionItemMessage.WAITING;
        statusColor = AuctionStatusColors.TO_ACCEPT_SELLER;
        break;
      case AuctionStatus.REJECTED:
        message = AuctionItemMessage.REJECTED;
        statusColor = AuctionStatusColors.REJECTED;
        break;
      case AuctionStatus.SOLD:
        message = AuctionItemMessage.SOLD;
        statusColor = AuctionStatusColors.SOLD;
        break;
      case AuctionStatus.ENDED:
        message = AuctionItemMessage.ENDED;
        statusColor = AuctionStatusColors.ENDED;
        break;
      default:
        return null;
    }
    return { message, statusColor };
  };

  const renderBuyerItemMessage = () => {
    let message;
    let statusColor;
    switch (item?.status) {
      case AuctionStatus.PUBLISHED:
        if (isHighestBid) {
          if (bidErrorMessage) {
            message = bidErrorMessage;
            statusColor = AuctionBarColors.RED;
          } else {
            if (item.proxyBidInPence) {
              message = `Your bid of ${preparePriceFormat(
                item.proxyBidInPence,
                true,
              )} is the highest. Your maximum bid is ${preparePriceFormat(
                organizationHighestBid,
                true,
              )}`;
            } else {
              message = `Your bid of ${preparePriceFormat(
                organizationHighestBid,
                true,
              )} is the highest`;
            }
            statusColor = AuctionBarColors.GREEN;
          }
        } else if (item?.bids?.length === AUCTION_BID_MAX_BIDS_LIMIT) {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest`;
          statusColor = AuctionBarColors.GRAY;
        } else if (item?.bids?.length > 0 && item?.bids?.length < AUCTION_BID_MAX_BIDS_LIMIT) {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} is not the highest - try again`;
          statusColor = AuctionBarColors.STRONG_RED;
        }
        break;
      case AuctionStatus.CANCELLED:
        message = AuctionItemMessage.CANCELLED;
        statusColor = AuctionBarColors.RED;
        break;
      case AuctionStatus.TO_ACCEPT:
        if (isHighestBid) {
          message = AuctionItemMessage.TO_ACCEPT_BUYER;
          statusColor = AuctionBarColors.YELLOW;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest.`;
          statusColor = AuctionBarColors.GRAY;
        }
        break;
      case AuctionStatus.WAITING:
        if (isHighestBid) {
          message = AuctionItemMessage.WAITING;
          statusColor = AuctionBarColors.YELLOW;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest.`;
          statusColor = AuctionBarColors.GRAY;
        }
        break;
      case AuctionStatus.REJECTED:
        if (isHighestBid) {
          message = AuctionItemMessage.REJECTED;
          statusColor = AuctionBarColors.RED;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest`;
          statusColor = AuctionBarColors.GRAY;
        }
        break;
      case AuctionStatus.SOLD:
        if (isHighestBid) {
          message = AuctionItemMessage.WON;
          statusColor = AuctionBarColors.GREEN;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} was not the highest. The winning bid was ${preparePriceFormat(
            item?.proxyBidInPence ?? item?.wonBidPriceInPence,
            true,
          )}`;
          statusColor = AuctionBarColors.GRAY;
        }
        break;
      default:
        return null;
    }
    setBidBarColor(statusColor);
    setBidBarMessage(message);
    return { message, statusColor };
  };

  // eslint-disable-next-line no-unused-vars
  const renderAuctionType = (isDesktop = true) => {
    return (
      <>
        <p
          className={`auction-item__status-type ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <AuctionTypeLabel hasBid={!!highestBidInPence} type={item.type} />
        </p>
        <p
          className={`auction-item__status-type ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <CurrentBidLabel hasBid={!!highestBidInPence} type={item.type} />
          <span className="search-min-price" style={getPriceColor(item.shouldPriceBeGreen)}>
            <CurrencyFormat
              value={
                pencesToPounds(highestBidInPence) ||
                pencesToPounds(item?.minimumPriceInPence) ||
                pencesToPounds(AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE)
              }
              thousandSeparator={true}
              allowNegative={false}
              prefix="£"
              displayType="text"
            />
          </span>
        </p>
      </>
    );
  };

  // const isAuctionLabel = useMemo(() => {
  //   return !!(item?.type || AuctionStatusTypeName[item?.type]);
  // }, [item.type]);

  const shortMeText = (text, length = 12) => {
    if (text?.length > length) {
      return `${text?.slice(0, length)}...`;
    }

    return text || '';
  };

  return (
    <>
      <Row
        className="search-box-of-car my-vehicles-box-of-car search-box-of-car--mobile"
        key={item.id}
        onClick={e => {
          if ((e?.target && e.target.closest('.search-box-of-car')) || !e?.target) {
            history.push({
              pathname: reverse(RouterPaths.SEARCH_DETAILS, { id: item.id }),
              state: {
                ...locationState,
              },
            });
          }
        }}
      >
        <Col
          sm={12}
          md={3}
          className="search-box-of-img search-box-of-img--mobile flex-column"
        >
          <div className="search-box-of-img-wrapper" ref={el => getRef(el)}>
            <img
              className="search-img-in-box"
              src={image}
              alt="car"
              onError={e => {
                e.target.src = noImageImg;
              }}
            />
          </div>
          <div className="search-title-wrapper--mobile">
            <p className="search-title--mobile">{title}</p>
          </div>
        </Col>
        <Col sm={12} md={9} className="h-100">
          <Row
            md={12}
            className="auction-basic-data-row h-100 d-flex flex-column position-relative"
          >
            <Col md={12} className="d-flex flex-column">
              {/*<Row>*/}
              {/*  <Col className="d-flex flex-row">*/}
              {/*    <div*/}
              {/*      className={`auction-basic-data__type-label text-uppercase ${*/}
              {/*        isAuctionLabel ? '' : 'hide'*/}
              {/*      }`}*/}
              {/*    >*/}
              {/*      <AuctionTypeLabel hasBid={!!item?.highestBidInPence} type={item.type} />*/}
              {/*    </div>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row className="pt-2">
                <Col>
                  <p className="search-title m-0 font-weight-bold">
                    {shortMeText(checkIsPropertySpecified(title, true), 56)}
                  </p>
                </Col>
              </Row>
              <Row
                md={12}
                className="search-content-left auction-basic-data-wrapper flex-grow-1"
              >
                <Col className="flex-grow-1">
                  <Row className="auction-basic-data__container">
                    <Col md={3} className="basic-data-col">
                      {' '}
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.year ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carCalendarBlue} alt="Vehicle Calendar" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(item?.vehicle?.year, false, '', 'N/S')}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.mileage ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carMileageBlue} alt="Cat Mileage Blue" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(
                            item?.vehicle?.mileage,
                            false,
                            'miles',
                            'N/S',
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.derivative ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carEngineBlue} alt="Vehicle Engine" />
                        </div>
                        <p className="m-0 ml-2">
                          <Derivative
                            derivative={item?.vehicle?.derivative}
                            limit={9}
                            className="auction-item__derivative-tooltip"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      {' '}
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.colour ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carColorBlue} alt="Vehicle Color" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(item?.vehicle?.colour, false, '', 'N/S')}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      {' '}
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.registrationPlate ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carRegistrationBlue} alt="Vehicle Registration" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(
                            item?.vehicle?.registrationPlate,
                            false,
                            '',
                            'N/S',
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.transmission ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carTransmissionBlue} alt="Vehicle Transmission" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(
                            capitalizeString(item?.vehicle?.transmission),
                            false,
                            '',
                            'N/S',
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.fuel ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carFuelBlue} alt="Vehicle Fuel" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(
                            capitalizeString(item?.vehicle?.fuel),
                            false,
                            '',
                            'N/S',
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      {' '}
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.city ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carLocationBlue} alt="Vehicle Location" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(item?.location?.city, false, '', 'N/S')}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <AuctionMainDescriptionContainer
                listType={auctionListType.SEARCH}
                item={item}
                windowFocus={windowFocus}
                organizationId={organizationId}
                patternSizes={[5, 5, 2]}
                pattern={[
                  SlotItems.AUCTION_END,
                  SlotItems.TIME_LEFT,
                  SlotItems.CURRENT_BID,
                  SlotItems.AUCTION_STATUS_BOX,
                ]}
              />
            </Col>
          </Row>
        </Col>
        <OrganizationRoleBox role={item.type} />
      </Row>
      {bidBarMessage && bidBarColor && (
        <Row
          md={12}
          className="auction-bar-message"
          style={{ backgroundColor: bidBarColor, color: 'white' }}
        >
          <p className="m-0 d-block font-weight-bold">{bidBarMessage}</p>
        </Row>
      )}
    </>
  );
};

AuctionItem.propTypes = {
  item: PropTypes.object.isRequired,
  isLatestVehiclesSection: PropTypes.bool,
  locationState: PropTypes.object,
  getRef: PropTypes.func,
};

AuctionItem.defaultProps = {
  isLatestVehiclesSection: false,
  locationState: {},
  getRef: () => {},
};

export default withRouter(AuctionItem);
