export const AuctionTime = {
  AUCTION_TIME_30: 'AUCTION_TIME_30',
  AUCTION_TIME_45: 'AUCTION_TIME_45',
  AUCTION_TIME_60: 'AUCTION_TIME_60',
  AUCTION_TIME_180: 'AUCTION_TIME_180',
  AUCTION_TIME_1440: 'AUCTION_TIME_1440',
  AUCTION_TIME_2880: 'AUCTION_TIME_2880',
  AUCTION_TIME_4320: 'AUCTION_TIME_4320',
  AUCTION_TIME_5760: 'AUCTION_TIME_5760',
  AUCTION_TIME_7200: 'AUCTION_TIME_7200',
  AUCTION_TIME_8640: 'AUCTION_TIME_8640',
  AUCTION_TIME_10080: 'AUCTION_TIME_10080',
  AUCTION_TIME_11520: 'AUCTION_TIME_11520',
  AUCTION_TIME_12960: 'AUCTION_TIME_12960',
  AUCTION_TIME_14400: 'AUCTION_TIME_14400',
  AUCTION_TIME_15840: 'AUCTION_TIME_15840',
  AUCTION_TIME_17280: 'AUCTION_TIME_17280',
  AUCTION_TIME_18720: 'AUCTION_TIME_18720',
  AUCTION_TIME_20160: 'AUCTION_TIME_20160',
};

export const AuctionTimeName = {
  AUCTION_TIME_60: '1 hour',
  AUCTION_TIME_180: '3 hours',
  AUCTION_TIME_1440: '1 day',
  AUCTION_TIME_2880: '2 days',
  AUCTION_TIME_4320: '3 days',
  AUCTION_TIME_5760: '4 days',
  AUCTION_TIME_7200: '5 days',
  AUCTION_TIME_8640: '6 days',
  AUCTION_TIME_10080: '7 days',
  AUCTION_TIME_11520: '8 days',
  AUCTION_TIME_12960: '9 days',
  AUCTION_TIME_14400: '10 days',
  AUCTION_TIME_15840: '11 days',
  AUCTION_TIME_17280: '12 days',
  AUCTION_TIME_18720: '13 days',
  AUCTION_TIME_20160: '14 days',
};

export const AuctionTimeHourFormat = {
  AUCTION_TIME_30: '30m:00s',
  AUCTION_TIME_45: '45m:00s',
  AUCTION_TIME_60: '1h:00m:00s',
  AUCTION_TIME_180: '3h:00m:00s',
  AUCTION_TIME_1440: '1 day',
  AUCTION_TIME_2880: '2 days',
  AUCTION_TIME_4320: '3 days',
  AUCTION_TIME_5760: '4 days',
  AUCTION_TIME_7200: '5 days',
  AUCTION_TIME_8640: '6 days',
  AUCTION_TIME_10080: '7 days',
  AUCTION_TIME_11520: '8 days',
  AUCTION_TIME_12960: '9 days',
  AUCTION_TIME_14400: '10 days',
  AUCTION_TIME_15840: '11 days',
  AUCTION_TIME_17280: '12 days',
  AUCTION_TIME_18720: '13 days',
  AUCTION_TIME_20160: '14 days',
};
