import { Regexp } from '../../constants/Regexp';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import { currentYear } from './currentYear';
import { reverseDateToString } from '../utils/date/Date.utils';
import { FormRegExp } from '../../constants/FormRegExp';
import { ValidationMessage } from '../../constants/ValidationMessage';

export const validateFormField = (
  value,
  type = FormFieldTypes.STRING,
  required = true,
  maxLength = null,
  minLength = null,
  comparativeValue = null,
  exact = false,
) => {
  let error = '';
  if (required && !value && value !== 0) {
    error = 'Field is required';
    return error;
  }
  if (
    (type === FormFieldTypes.STRING &&
      value.length > 0 &&
      !value.match(FormRegExp.STRING_WITH_SPECIAL_CHARS)) ||
    (type === FormFieldTypes.COMPANY_REGISTRATION_NUMBER &&
      value.length > 0 &&
      !value.match(FormRegExp.ALPHANUMERIC_WITHOUT_SPACES_UPPERCASE)) ||
    (type === FormFieldTypes.VIN &&
      value.length > 0 &&
      !value.match(FormRegExp.ALPHANUMERIC_WITHOUT_SPACES)) ||
    (type === FormFieldTypes.NAME && value.length > 0 && !value.match(FormRegExp.STRING))
  ) {
    error = `Type correct characters`;
    return error;
  }
  if (type === FormFieldTypes.NUMBER && value.length > 0 && !value.match(Regexp.INTEGER)) {
    error = `Type only numbers`;
    return error;
  }
  if (type === FormFieldTypes.EMAIL && !value.match(Regexp.EMAIL)) {
    error = `Email has the wrong format`;
    return error;
  }
  if (minLength === maxLength && value.length !== minLength && exact) {
    error = `Length equals ${minLength} characters`;
    return error;
  }
  if (maxLength && value && value.length > maxLength) {
    error = `Max length equals ${maxLength} characters`;
    return error;
  }
  if (minLength && value && value.length < minLength) {
    error = `Min length equals ${minLength} characters`;
    return error;
  }

  if (type === FormFieldTypes.PASSWORD && value !== comparativeValue) {
    error = `Password must be the same`;
    return error;
  }
  if (
    (type === FormFieldTypes.MONTH && !value.match(Regexp.EXP_MONTH)) ||
    (type === FormFieldTypes.YEAR && !value.match(Regexp.EXP_YEAR))
  ) {
    error = `Date has the wrong format`;
    return error;
  }
  if (
    (type === FormFieldTypes.PROD_YEAR && +value > currentYear()) ||
    (type === FormFieldTypes.PROD_YEAR && +value < currentYear() - 100)
  ) {
    error = `The year cannot be less than 100 years back and not higher than the current one`;
    return error;
  }
  if (type === FormFieldTypes.DERIVATIVE && !value.match(Regexp.DERIVATIVE)) {
    error = `Derivative must be between 1 and 150 characters`;
    return error;
  }
  if (
    type === FormFieldTypes.PREV_KEEPERS &&
    value.length > 0 &&
    !value.match(Regexp.INTEGER)
  ) {
    error = `Number of previous keepers cannot be less than 0 and may contain only numbers`;
    return error;
  }
  if (
    type === FormFieldTypes.DOORS &&
    value.length > 0 &&
    (!value.match(Regexp.INTEGER) || +value < 1)
  ) {
    error = `Number of doors cannot be less than 1 and may contain only numbers`;
    return error;
  }

  if (type === FormFieldTypes.DATE_OF_REG) {
    const year = Number(reverseDateToString(value).split('-')[0]);
    if (Number.isNaN(year)) {
      error = `Date has the wrong format`;
      return error;
    }
    if (new Date() < new Date(value) || year < currentYear() - 50) {
      error = `The date of first registration cannot be older than 50 years back and not higher than the current date`;
      return error;
    }
  }

  if (
    type === FormFieldTypes.ENGINE_SIZE &&
    value.length > 0 &&
    (!value.match(Regexp.INTEGER) || +value < 1)
  ) {
    error = `Engine size cannot be less than 1cc and may contain only numbers`;
    return error;
  }

  if (value.length && type === FormFieldTypes.URL && !value.match(FormRegExp.URL_NO_HTTPS)) {
    error = ValidationMessage.WEBSITE_ADDRESS;
    return error;
  }

  return null;
};
