import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import FormCurrencyInputField from '../../common/components/formFields/FormCurrencyInputField';
import { AuctionTime, AuctionTimeName } from '../../constants/AuctionTime';
import {
  AuctionStatusType,
  AuctionStatusTypeName,
  AvailableAuctionStatuses,
} from '../../constants/AuctionStatusType';
import FormGroupRadioField from '../../common/components/formFields/FormGroupRadioField';
import {
  AuctionPublishType,
  AuctionPublishTypeName,
} from '../../constants/AuctionPublishType';
import FormDatePickerField from '../../common/components/formFields/FormDatePickerField';
import {
  clearDescriptionsAndSettingsErrors,
  fetchOrganizationGroup,
  fetchOrganizationLocations,
  getDraft,
  saveDescriptionAndSettingsDraft,
  validateDescriptionAndSettings,
  validateMedia,
} from '../AddVehicleActions';
import { reverseDateToString } from '../../common/utils/date/Date.utils';
import { checkIsFieldEmpty } from '../../common/helpers/checkIsFieldEmpty';
import { parseCurrencyToIntIfString } from '../../common/helpers/parseCurrencyToIntIfString';
import { DueInDateType, DueInDateTypeName } from '../../constants/DueInDateType';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import { prepareSelectLabel } from '../../common/helpers/prepareSelectLabel';
import { prepareTryingToDealDeliveryTypeLabel } from '../../common/helpers/prepareTryingToDealDeliveryTypeLabel';
import validationSchema from './LaunchVehicleForm.schema';
import { fetchOrganizationDetails } from '../../settings/SettingsActions';
import AddVehicleFooter from '../AddVehicleFooter';
import { setVehicleFilter } from "../../myvehicles/MyVehiclesActions";
import { AuctionStatus } from "../../constants/AuctionStatus";
import history from "../../browserHistory";
import RouterPaths from "../../constants/RouterPaths";
// import {REQUIRED_PHOTOS_AMOUNT} from "../../constants/RequiredPhotosAmount";

const auctionTimes = constantToSelect(AuctionTimeName);
const auctionTypes = constantToSelect(
  Object.keys(AuctionStatusType)
    .filter(el => AvailableAuctionStatuses.includes(el))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: AuctionStatusTypeName[key],
      };
    }, {}),
);

const auctionPublishTypes = constantToSelect(AuctionPublishTypeName);
const dueInDateTypes = constantToSelect(DueInDateTypeName);

const LaunchVehicleForm = ({ form, prevForm }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const organizationLocations = useSelector(state =>
    state.addVehicle.get('organizationLocations'),
  );
  const organizationGroup = useSelector(state => state.addVehicle.get('organizationGroup'));
  const validationErrors = useSelector(state => state.addVehicle.get('validationErrors'));
  const id = useSelector(state => state.addVehicle.get('id'));
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    const checkIfDisableNextButton = () => {
      const { PHYSICAL, MINIMUM_PRICE, TRYING_TO_DEAL } = AuctionStatusType;
      const {
        description,
        vehicleLocation,
        timeOption,
        type,
        minimumPriceInPence,
        autoAcceptPriceInPence,
        tryingToDealDeliveryDate,
        tryingToDealDeliveryDateType,
      } = form?.values;
      switch (form?.values?.type?.value) {
        case PHYSICAL: {
          checkIsFieldEmpty(
            [description, vehicleLocation, timeOption, type, autoAcceptPriceInPence],
            isDisabled => setIsNextDisabled(isDisabled),
          );
          break;
        }
        case MINIMUM_PRICE: {
          checkIsFieldEmpty(
            [description, vehicleLocation, timeOption, type, minimumPriceInPence],
            isDisabled => setIsNextDisabled(isDisabled),
          );
          break;
        }
        case TRYING_TO_DEAL: {
          checkIsFieldEmpty(
            [
              description,
              vehicleLocation,
              timeOption,
              type,
              tryingToDealDeliveryDateType,
              tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
                ? tryingToDealDeliveryDate
                : true,
            ],
            isDisabled => setIsNextDisabled(isDisabled),
          );
          break;
        }
        default:
          return null;
      }
      return null;
    };
    if (form.values) {
      checkIfDisableNextButton();
    }
  }, [form]);

  const preparedOrganizationLocations =
    organizationLocations &&
    organizationLocations.map(item => {
      return {
        value: item?.id,
        label: item?.postalCode?.postcode,
      };
    });

  useEffect(() => {
    // dispatch(fetchDescriptionTemplate());
    dispatch(fetchOrganizationLocations());
    dispatch(fetchOrganizationGroup());
  }, [dispatch]);

  const handleSaveDraft = () => {
    const {
      description,
      vehicleLocation,
      type,
      minimumPriceInPence,
      autoAcceptPriceInPence,
      tryingToDealDeliveryDate,
      tryingToDealDeliveryDateType,
      timeOption,
      publishTarget,
    } = form?.values;

    const descriptionAndSettings = {
      description,
      locationId: vehicleLocation?.value,
      type: type?.value,
      minimumPriceInPence:
        type?.value === AuctionStatusType.MINIMUM_PRICE
          ? parseCurrencyToIntIfString(minimumPriceInPence) * 100
          : null,
      autoAcceptPriceInPence:
        type?.value === AuctionStatusType.PHYSICAL
          ? parseCurrencyToIntIfString(autoAcceptPriceInPence) * 100
          : null,
      tryingToDealDeliveryDate:
        type?.value === AuctionStatusType.TRYING_TO_DEAL &&
        tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
          ? reverseDateToString(tryingToDealDeliveryDate)
          : null,
      tryingToDealDeliveryDateType:
        type?.value === AuctionStatusType.TRYING_TO_DEAL
          ? tryingToDealDeliveryDateType?.value
          : null,
      timeOption: timeOption?.value,
      publishTarget,
    };

    dispatch(
      saveDescriptionAndSettingsDraft(descriptionAndSettings, () => dispatch(getDraft(id))),
    );
    form.setErrors({});
  };

  const handleStatusChange = e => {
    dispatch(clearDescriptionsAndSettingsErrors());
    if (e.value === AuctionStatusType.TRYING_TO_DEAL) {
      form.setFieldValue('tryingToDealDeliveryDate', new Date());
    } else {
      form.setFieldValue('tryingToDealDeliveryDate', null);
    }
    form.setFieldValue('minimumPriceInPence', '');
    form.setFieldValue('autoAcceptPriceInPence', '');
  };

  return (
    <>
      <AddVehicleHeader
        saveDraftButtonHandler={handleSaveDraft}
        saveDraftButtonDisabled={isLoading}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Publish Listing</h5>
            <p className="text-sm mt-2">
              All vehicle details are now complete. Please select the parameters of your
              auction below.
            </p>
          </Col>
        </Row>
        <Form autoComplete="off" className="text-left mt-4">
          <Row>
            <Col md={4} sm={12}>
              <FormSelectField
                name="vehicleLocation"
                label="Location"
                options={[...preparedOrganizationLocations]}
                required
              />
            </Col>
            <Col md={4} sm={12}>
              <FormSelectField
                name="timeOption"
                label="Time"
                options={auctionTimes}
                required
              />
            </Col>
            <Col md={4} sm={12}>
              <FormSelectField
                name="type"
                label="Status"
                options={auctionTypes}
                onChangeHandler={handleStatusChange}
                required
              />
            </Col>
          </Row>
          <Row className="mt-3">
            {form?.values?.type?.value === AuctionStatusType.MINIMUM_PRICE && (
              <Col md={6} sm={12}>
                <FormCurrencyInputField
                  name="minimumPriceInPence"
                  label="No Reserve Price"
                  invalid={
                    !!form.errors.minimumPriceInPence ||
                    validationErrors?.vehicleDescriptionAndSettingsForm
                  }
                  required
                />
              </Col>
            )}
            {form?.values?.type?.value === AuctionStatusType.PHYSICAL && (
              <Col md={6} sm={12}>
                <FormCurrencyInputField
                  name="autoAcceptPriceInPence"
                  label="Reserve Price"
                  invalid={
                    !!form.errors.autoAcceptPriceInPence ||
                    validationErrors?.vehicleDescriptionAndSettingsForm
                  }
                  required
                />
              </Col>
            )}
            {form?.values?.type?.value === AuctionStatusType.TRYING_TO_DEAL && (
              <Col md={6} sm={12}>
                <FormSelectField
                  name="tryingToDealDeliveryDateType"
                  label="Due in date"
                  options={dueInDateTypes}
                  required
                />
              </Col>
            )}
            {form?.values?.type?.value === AuctionStatusType.TRYING_TO_DEAL &&
              form?.values?.tryingToDealDeliveryDateType?.value ===
                DueInDateType.CUSTOM_DATE && (
                <Col md={6} sm={12}>
                  <FormDatePickerField
                    name="tryingToDealDeliveryDate"
                    label="Delivery date"
                    error={
                      !!form.errors.tryingToDealDeliveryDate ||
                      validationErrors?.vehicleDescriptionAndSettingsForm
                    }
                    readOnly
                    required
                  />
                </Col>
              )}
          </Row>
          <Row>
            <Col md={12} className="pt-4">
              {organizationGroup && (
                <FormGroupRadioField
                  name="publishTarget"
                  radios={auctionPublishTypes}
                  classNames="auction-publish-radios"
                />
              )}
            </Col>
          </Row>
        </Form>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          form.handleSubmit();
        }}
        backButtonHandler={prevForm}
        nextButtonDisabled={isLoading || isNextDisabled}
        nextButtonText="SAVE"
      />
    </>
  );
};

const LaunchVehicle = ({ prevForm, generalValues, nextForm }) => {
  const dispatch = useDispatch();
  const descriptionAndSettings = useSelector(state =>
    state.addVehicle.get('descriptionAndSettings'),
  );
  const mainLocation = useSelector(
    state => state.myOrganization.get('organizationDetails')?.mainLocation,
  );
  // const photos = Array.from(useSelector(state => state.addVehicle.get('photos')));

  const organizationLocations = useSelector(state =>
    state.addVehicle.get('organizationLocations'),
  );

  const prepareTimeOptionSelect = () => {
    const {
      AUCTION_TIME_60,
      AUCTION_TIME_180,
      AUCTION_TIME_1440,
      AUCTION_TIME_2880,
      AUCTION_TIME_4320,
    } = AuctionTime;
    const {
      AUCTION_TIME_60: AUCTION_TIME_60_LABEL,
      AUCTION_TIME_180: AUCTION_TIME_180_LABEL,
      AUCTION_TIME_1440: AUCTION_TIME_1440_LABEL,
      AUCTION_TIME_2880: AUCTION_TIME_2880_LABEL,
      AUCTION_TIME_4320: AUCTION_TIME_4320_LABEL,
    } = AuctionTimeName;
    switch (descriptionAndSettings?.timeOption) {
      case AUCTION_TIME_60:
        return {
          label: AUCTION_TIME_60_LABEL,
          value: AUCTION_TIME_60,
        };
      case AUCTION_TIME_180:
        return {
          label: AUCTION_TIME_180_LABEL,
          value: AUCTION_TIME_180,
        };
      case AUCTION_TIME_1440:
        return {
          label: AUCTION_TIME_1440_LABEL,
          value: AUCTION_TIME_1440,
        };
      case AUCTION_TIME_2880:
        return {
          label: AUCTION_TIME_2880_LABEL,
          value: AUCTION_TIME_2880,
        };
      case AUCTION_TIME_4320:
        return {
          label: AUCTION_TIME_4320_LABEL,
          value: AUCTION_TIME_4320,
        };
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(fetchOrganizationDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVehicleLocation = () => {
    const locations =
      organizationLocations &&
      organizationLocations?.map(item => {
        return {
          value: item?.id,
          label: item?.postalCode?.postcode,
        };
      });

    if (descriptionAndSettings?.locationId) {
      return locations?.find(item => item?.value === descriptionAndSettings?.locationId);
    }

    return locations?.find(item => item?.value === mainLocation?.id);
  };

  const initialValues = {
    description: descriptionAndSettings ? descriptionAndSettings?.description : '',
    defaultDescription: descriptionAndSettings?.defaultDescription ? descriptionAndSettings?.defaultDescription : '',
    vehicleLocation: getVehicleLocation(),
    type: (() => {
      if (descriptionAndSettings?.type && AvailableAuctionStatuses.includes(descriptionAndSettings?.type)) {
        return {
          label: AuctionStatusTypeName?.[descriptionAndSettings?.type]
              ? AuctionStatusTypeName?.[descriptionAndSettings?.type]
              : prepareSelectLabel(descriptionAndSettings?.type),
          value: descriptionAndSettings?.type,
        }
      }

      return {
        label: '',
        value: ''
      }
    })(),
    autoAcceptPriceInPence: descriptionAndSettings?.autoAcceptPriceInPence
      ? descriptionAndSettings?.autoAcceptPriceInPence / 100
      : '',
    minimumPriceInPence: descriptionAndSettings
      ? descriptionAndSettings?.minimumPriceInPence / 100
      : '',
    timeOption: descriptionAndSettings?.timeOption ? prepareTimeOptionSelect() : null,
    tryingToDealDeliveryDate: descriptionAndSettings?.tryingToDealDeliveryDate || new Date(),
    publishTarget: descriptionAndSettings?.publishTarget
      ? descriptionAndSettings?.publishTarget
      : AuctionPublishType.EVERYWHERE,
    tryingToDealDeliveryDateType: descriptionAndSettings?.tryingToDealDeliveryDateType
      ? {
          label: prepareTryingToDealDeliveryTypeLabel(
            descriptionAndSettings?.tryingToDealDeliveryDateType,
          ),
          value: descriptionAndSettings?.tryingToDealDeliveryDateType,
        }
      : null,
  };

  const handleSaveDraft = (values) => {
    const {
      description,
      vehicleLocation,
      type,
      minimumPriceInPence,
      autoAcceptPriceInPence,
      tryingToDealDeliveryDate,
      tryingToDealDeliveryDateType,
      timeOption,
      publishTarget,
    } = values;

    const descriptionAndSettings = {
      description,
      locationId: vehicleLocation?.value,
      type: type?.value,
      minimumPriceInPence:
          type?.value === AuctionStatusType.MINIMUM_PRICE
              ? parseCurrencyToIntIfString(minimumPriceInPence) * 100
              : null,
      autoAcceptPriceInPence:
          type?.value === AuctionStatusType.PHYSICAL
              ? parseCurrencyToIntIfString(autoAcceptPriceInPence) * 100
              : null,
      tryingToDealDeliveryDate:
          type?.value === AuctionStatusType.TRYING_TO_DEAL &&
          tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
              ? reverseDateToString(tryingToDealDeliveryDate)
              : null,
      tryingToDealDeliveryDateType:
          type?.value === AuctionStatusType.TRYING_TO_DEAL
              ? tryingToDealDeliveryDateType?.value
              : null,
      timeOption: timeOption?.value,
      publishTarget,
    };

    dispatch(
        saveDescriptionAndSettingsDraft(descriptionAndSettings, () => {
          dispatch(setVehicleFilter(AuctionStatus.DRAFT));
          history.push(`${RouterPaths.MY_VEHICLES}#vehicleFilter`);
        }),
    );
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await dispatch(
      validateMedia(
        async () => {
          await dispatch(validateDescriptionAndSettings(values, setErrors));
          setSubmitting(false);
        },
        () => {
          // nextForm(
          //   (photos?.filter(el => el?.fileUrl) || []).length > 0
          //     ? AddVehicleStepsMapping.PHOTOS_SUMMARY
          //     : AddVehicleStepsMapping.PHOTOS,
          // );

          handleSaveDraft(values);
          setSubmitting(false);
        },
        true,
          true
      ),
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      {form => (
        <LaunchVehicleForm
          form={form}
          prevForm={prevForm}
          generalValues={generalValues}
          nextForm={nextForm}
        />
      )}
    </Formik>
  );
};

LaunchVehicle.propTypes = {
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default LaunchVehicle;
