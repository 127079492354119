import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { get } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import ResetPasswordSchema from './ResetPasswordPage.schema';
import { resetPassword } from '../AuthActions';
import CommonButton from '../../common/components/CommonButton';
import RouterPaths from '../../constants/RouterPaths';
import { setSearchQuery } from '../../search/SearchActions';
import logo from '../../assets/img/logo-itrade-new.png';
import whiteCar from '../../assets/img/white-car.png';
import FormInputField, {
  FormInputFieldType,
} from '../../common/components/formFields/FormInputField';

const initialValues = {
  password: '',
  passwordRepeat: '',
};

const ResetPasswordPage = ({ match }) => {
  const token = get(match, 'params.token', null);

  const dispatch = useDispatch();

  const onSubmitHandler = ({ password, passwordRepeat }, { setFieldValue, setSubmitting }) => {
    dispatch(
      resetPassword({ password, passwordRepeat, token }, () => {
        setFieldValue('password', '');
        setFieldValue('passwordRepeat', '');
      }),
    );

    setSubmitting(false);
  };

  return (
    <Row className="auth-page m-0 p-0">
      <Col className="auth-page__left-column" lg={3} md={3} sm={12}>
        <Link
          to={RouterPaths.HOMEPAGE}
          onClick={() => dispatch(setSearchQuery(''))}
          className="page-logo-link"
        >
          <img src={logo} alt="logo" />
        </Link>
        <h2 className="auth-page__big-title font-weight-bold auth-page__hide-on-mobile">
          Buy & Sell trade vehicles from the UK&apos;s most trusted dealers.
        </h2>
        <img
          className="auth-page__white-car auth-page__hide-on-mobile"
          src={whiteCar}
          alt="logo"
        />
      </Col>
      <Col className="auth-page__right-column ml-auto mr-auto" lg={5} md={9} sm={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validationSchema={ResetPasswordSchema}
        >
          {() => (
            <Form method="" action="" className="reset-password-form">
              <h3 className="font-weight-bold float-left">Reset Password</h3>
              <FormInputField
                name="password"
                label="Password"
                type={FormInputFieldType.PASSWORD}
              />
              <FormInputField
                name="passwordRepeat"
                label="Repeat Password"
                type={FormInputFieldType.PASSWORD}
              />
              <CommonButton label="Reset password" className="mt-3 w-100" type="submit" />
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default withRouter(ResetPasswordPage);
