import uuid from 'react-uuid';
import jwt from 'jsonwebtoken';
import { getToken } from '../auth/AuthActions';

export const BID_PLACED = 'BID_PLACED';
export const BID_REMOVED = 'BID_REMOVED';
export const AUCTION_WAITING = 'AUCTION_WAITING';
export const AUCTION_TO_ACCEPT = 'AUCTION_TO_ACCEPT';
export const AUCTION_SOLD = 'AUCTION_SOLD';
export const AUCTION_REJECTED = 'AUCTION_REJECTED';
export const AUCTION_CANCELLED = 'AUCTION_CANCELLED';
export const AUCTION_UPDATED = 'AUCTION_UPDATED';
export const AUCTION_DELETED = 'AUCTION_DELETED';
export const AUCTION_ENDED = 'AUCTION_ENDED';
export const AUCTION_PUBLISHED = 'AUCTION_PUBLISHED';

export const SSE_BUYER = 'SSE_BUYER';
export const SSE_SELLER = 'SSE_SELLER';

const SSE_URL = process.env.REACT_APP_SSE_URL || 'https://dealerssebackend.itradestaging.link';
const DEVICE_ID = 'DEVICE_ID';

const getDeviceId = () => {
  let deviceId = sessionStorage.getItem(DEVICE_ID);
  if (deviceId) {
    return deviceId;
  }

  deviceId = uuid();
  sessionStorage.setItem(DEVICE_ID, deviceId);
  return deviceId;
};

export class SSEClient {
  constructor(type) {
    this.client = null;
    this.type = type;
  }

  listen(auctionsIds, callback) {
    const token = getToken();
    const decodedToken = jwt.decode(token);

    this.close();

    if (Array.isArray(auctionsIds)) {
      const deviceId = getDeviceId();
      const query = `${auctionsIds.map(id => `auctionIds=${id}&`).join('')}_st=${
        decodedToken?._st
      }&deviceId=${deviceId}`;

      this.client = new EventSource(
        `${SSE_URL}/sse/auction/${this.type === SSE_BUYER ? 'buyer' : 'seller'}?${query}`,
      );
      // this.client.addEventListener(
      //   AUCTION_ENDED,
      //   function(e) {
      //     callback(e.data);
      //   },
      //   false,
      // );

      const getEventData = e => {
        callback(JSON.parse(e.data));
      };

      this.client.addEventListener(BID_PLACED, e => getEventData(e), false);
      this.client.addEventListener(BID_REMOVED, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_WAITING, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_TO_ACCEPT, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_SOLD, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_REJECTED, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_CANCELLED, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_UPDATED, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_DELETED, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_ENDED, e => getEventData(e), false);
      this.client.addEventListener(AUCTION_PUBLISHED, e => getEventData(e), false);
    }
  }

  close() {
    if (this?.client) {
      this.client.close();
      this.client = null;
    }
  }
}
