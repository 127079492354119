export const Transmission = {
  MANUAL: 'Manual',
  AUTOMATIC: 'Automatic',
  SEMI_AUTOMATIC: 'Semi-Automatic',
  CVT: 'CVT',
  DUAL_CLUTCH: 'Dual-clutch',
};

export const Fuel = {
  PETROL: 'Petrol',
  DIESEL: 'Diesel',
  ELECTRIC: 'Electric',
  HYBRID: 'Hybrid',
  LPG: 'LPG',
  CNG: 'CNG',
  HYDROGEN: 'Hydrogen',
};

export const Doors = [1, 2, 3, 4, 5];

export default {
  Transmission,
  Fuel,
  Doors,
};
